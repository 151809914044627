import React from "react";
// import { Banner,OurClients,RemoteWorking } from '../../components/pages';
import AboutUs from "../../components/aboutUs";
import StaffIqValues from "../../components/staffIQ";
import { Banner, OurClients, RemoteWorking, HowDifferent, Testimonials, FreeTrial,  ContactUs, } from '../../components/pages';
import FreqAskedQuestions from "../../components/freqAskedQuestion";
import ContactUsForm from "../../components/contactUsForm";
import PlanPricing from "components/pages/PlanPricing";
import JoinAS from "components/joinAs";
export class Overview extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			url:"",
			show : false,
		}
	  }

    handleDialogBox = () => {
      this.setState({
        show : !this.state.show
      })
      }
  render() {

    // let { match: { params } } = this.props;
    return (
      <>
      {this.state.show ? <JoinAS show={this.state.show} handleDialogBox={this.handleDialogBox} email={this.state.email}/> : null}
        <Banner handleDialogBox={this.handleDialogBox}/>
        <AboutUs handleDialogBox={this.handleDialogBox}/>
        <div>
          <OurClients />
        </div>
        <StaffIqValues />
        <div>
          <RemoteWorking />
        </div>
        

         {/* <div>
          <HowDifferent />
        </div> */}
        <div>
          <Testimonials />
        </div>
        <div>
          <FreeTrial handleDialogBox={this.handleDialogBox}/>
        </div>
        <div>
          <PlanPricing />
        </div>

        <div>
          <ContactUs />
        </div>
        {/* <FreqAskedQuestions /> */}
        <section id="contact-form">
          <ContactUsForm />
        </section>
        
      </>
    );
  }
}


