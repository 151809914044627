import { axiosInstance } from './utilities';

//var beURL = 'http://staffiqdev-be.qutrix.io';
//var beURL = 'http://be.staffatom.com';
var beURL = process.env.REACT_APP_BEURL;

//var bePort = '80'; // or use 84 as needed
var bePort = process.env.REACT_APP_BEPORT;

export var api = async function({ method = 'get', api, body, status = false, token = '', baseURL = 'normal' }) {
	return await new Promise((resolve, reject) => {
		// setting token
		// axiosInstance.defaults.headers.common['Authorization'] = token;
		axiosInstance.defaults.headers.common[ "Authorization"  ] = localStorage.getItem("user") 
     ? `Basic ${localStorage.getItem("user")}`
        : "";
		
		axiosInstance
			[method](`${getServiceUrl(baseURL)}${api}`, body ? body : '')
			.then((data) => {
				resolve(statusHelper(status, data));
			})
			.catch((error) => {
				try {
					if (error.response) {
						reject(statusHelper(status, error.response));
					} else {
						reject(error);
					}
				} catch (err) {
					// console.log(err);
					reject(err);
				}
			});
	});
};

var statusHelper = (status, data) => {
	if (data.status === 401 || data.status === 403) {
		localStorage.clear()
	}
	if (status) {
		return {
			status: data.status,
			...data.data,
		};
	} else {
		return data.data;
	}
};

let getServiceUrl = (baseURL) => {
	let finalURL = '';

	switch (baseURL) {
		case 'normal':
			finalURL = (beURL) + ':' + (bePort) + '81';
			break;
		case 'auth':
			finalURL = (beURL) + ':' + (bePort) + '83/';
			break;
		case 'agency':
			finalURL = (beURL) + ':' + (bePort) + '87/';
			break;
		case 'client':
			finalURL = (beURL) + ':' + (bePort) + '85/';
			break;
		case 'contact':
			finalURL = (beURL) + ':' + (bePort) + '89/';
			break;
		case 'subscription':
			finalURL = (beURL) + ':' + (bePort) + '89/';
			break;
		case 'job':
			finalURL = (beURL) + ':' + (bePort) + '84/';
			break;
		case 'tax':
			finalURL = (beURL) + ':' + (bePort) + '84/';
			break;

		// case 'discount':
		// 	finalURL = (beURL) + ':' + (bePort) + '89/';
		// 	break;
		default:
			finalURL = (beURL) + ':' + (bePort) + '83/';
			break;
	}

	return finalURL;
};
