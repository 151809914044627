import React, { useEffect, useLayoutEffect, useState } from "react";
import { Header, Footer } from "../../components/common";
import RedirectConfirmation from "components/common/RedirectConfirmation";
// import AboutUs from "../../components/aboutUs";

export const MainLayout = ({ children }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const handleLoad = () => {
      setLoaded(true);
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);
  return (
    <>
     {loaded &&  <RedirectConfirmation />}
      <Header />
      {children}
      <Footer />
    </>
  );
};

