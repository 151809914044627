import React from "react";
import "./style.scss"
import atom_blue from "../../assets/svg/atom_blue.svg"
import atom_white from "../../assets/svg/atom_white.svg"
import clock_transparent from "../../assets/svg/clock-transparent.svg"
import clock_solid from "../../assets/svg/clock-solid.svg"
import periodic_table_white from "../../assets/svg/periodic_table_white.svg"
import periodic_table_blue from "../../assets/svg/periodic_table_blue.svg"
import customer_solid from "../../assets/svg/customer-solid.svg"
import customer_transparent from "../../assets/svg/customer-transparent.svg"
import stack_blue from "../../assets/svg/stack_blue.svg"
import stack_white from "../../assets/svg/stack_white.svg"
import timeOffSolid from "../../assets/svg/timeOffSolidWarning.svg"
import timeOffTransparent from "../../assets/svg/timeOffTransparentWarning.svg"
import stat_solid from "../../assets/svg/stat-solid.svg"
import stat_transparent from "../../assets/svg/stat-transparent.svg"
import microscope_blue from "../../assets/svg/microscope_blue.svg"
import microscope_white from "../../assets/svg/microscope_white.svg"
import subscription_solid from "../../assets/svg/Subscription-solid.svg"
import subscription_white from "../../assets/svg/Subscription-white.svg"
import settings_solid from "../../assets/svg/settings-solid.svg"
import settings_white from "../../assets/svg/settings-transparent.svg"

const AgencyModulesSpinner = ({ selectedModule, onModuleSelect }) => {

    const agencyModulesData = [
        {
            "moduleIndex": 1,
            "imageLight": atom_white,
            "imageDark": atom_blue
        },
        {
            "moduleIndex": 3,
            "imageLight": clock_transparent,
            "imageDark": clock_solid
        }, {
            "moduleIndex": 5,
            "imageLight": periodic_table_white,
            "imageDark": periodic_table_blue
        }, {
            "moduleIndex": 7,
            "imageLight": customer_transparent,
            "imageDark": customer_solid
        }, {
            "moduleIndex": 9,
            "imageLight": stack_white,
            "imageDark": stack_blue
        }, {
            "moduleIndex": 11,
            "imageLight": timeOffTransparent,
            "imageDark": timeOffSolid
        }, {
            "moduleIndex": 13,
            "imageLight": stat_transparent,
            "imageDark": stat_solid
        }, {
            "moduleIndex": 14,
            "imageLight": microscope_white,
            "imageDark": microscope_blue
        }, {
            "moduleIndex": 15,
            "imageLight": subscription_white,
            "imageDark": subscription_solid
        }, {
            "moduleIndex": 16,
            "imageLight": settings_white,
            "imageDark": settings_solid
        }
    ]

    return (
        <div className="agency-module-container">
            <div className="agency-module-spinner">
                <div className="circle-wrapper">
                    <div className="circle">
                        <div className="agency-parent">
                            <div className="agency">Agency</div>
                            <div className="streamline-staffing-effortless">Streamline staffing effortlessly. Elevate your agency's potential with every click.</div>
                            {/* <div className="frame-parent">
                            <div className="subscribers-wrapper">
                                <div className="subscribers">Subscribers</div>
                            </div>
                            <div className="frame-group">
                                <img className="frame-child" alt="" src={userIcon} />
                                <div className="subscribers">1,61,5416</div>
                            </div>
                        </div> */}
                        </div>
                    </div>

                </div>
                <div className="image-holder">
                    {agencyModulesData.map((item, index) => {
                       

                        return (<div className='module-img-container' key={index} onClick={() => onModuleSelect(item.moduleIndex)} style={{ backgroundColor: item.moduleIndex === selectedModule ? '#180065' : '#ffffff'}}>

                            <img src={item.moduleIndex === selectedModule ? item.imageLight : item.imageDark} alt="" />

                        </div>
                        )
                    })}

                </div>
            </div>

        </div>
    )
}

export default React.memo(AgencyModulesSpinner);