import { stripeSubscriptionApi } from '../service/apiVariables';
import { addQuery } from '../service/helperFunctions';


export const getAllPricingPlan = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, stripeSubscriptionApi.getAllPricingPlan);
        api({ ...stripeSubscriptionApi.getAllPricingPlan })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getPricingPlanById = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery( query, stripeSubscriptionApi.getPricingPlanById);
        api({ ...stripeSubscriptionApi.getPricingPlanById })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const postStripePayment = (body) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery( stripeSubscriptionApi.payment);
        api({ ...stripeSubscriptionApi.payment, body })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const stripeCheckout = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery( query, stripeSubscriptionApi.checkout);
        api({ ...stripeSubscriptionApi.checkout, })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};
