
const routers = [
  {
    path: '/',
    auth: false,
    exact: true,
    redirect: '/overView',
  },
  {
    component: "MainLayout",
    path: "/overView",
    auth: false,
    name:'mainLayout',
    exact: true,
    childrens: [
      {
        component: "Overview",
        path: "/",
        name: "Overview",
        auth: false,
        exact: true
      }
    ]
  },
  {
    component: "TermsLayout",
    path: "/terms-and-conditions",
    auth: false,
    exact: true,
    childrens: [
      {
      component: 'Terms',
      path: '/',
      auth: false,
      exact: true,
      }
    ]
  },
  {
    component: "TermsLayout",
    path: "/privacy-statement",
    auth: false,
    exact: true,
    childrens: [
      {
      component: 'Privacy',
      path: '/',
      auth: false,
      exact: true,
      }
    ]
  },
  {
    component: "TermsLayout",
    path: "/disclaimer",
    auth: false,
    exact: true,
    childrens: [
      {
      component: 'Disclaimer',
      path: '/',
      auth: false,
      exact: true,
      }
    ]
  },
  {
    component: "TermsLayout",
    path: "/understand-app-gps-tracking",
    auth: false,
    exact: true,
    childrens: [
      {
      component: 'AppLocationUsage',
      path: '/',
      auth: false,
      exact: true,
      }
    ]
  },
  {
    component: "AuthLayout",
    path: "/subscription/:action/:plan/:type",
    auth: false,
    exact: true,

    childrens: [
      {
        component: 'Subscription',
        path: '/',
        componentPath: 'pages/subscription',
        auth: false,
        exact: true,
     },
    ]
  },
  // {
  //   component: "AuthLayout",
  //   path: "/freeAccount",
  //   auth: false,
  //   exact: true,
  //   childrens: [
  //     {
  //       component: 'AccountRegistration',
  //       path: '/',
  //       componentPath: 'pages/accountRegistration',
  //       auth: false,
  //       exact: true,
  //    },
  //   ]
  // },
  {
    component: "AuthLayout",
    path: "/freeAccount/:action",
    auth: false,
    exact: true,
    childrens: [
      {
        component: 'AccountRegistration',
        path: '/',
        componentPath: 'pages/accountRegistration',
        auth: false,
        exact: true,
     },
    ]
  },

  {
    component: "AuthLayout",
    path: "/createFreeProviderAccount",
    auth: false,
    exact: true,
    childrens: [
      {
        component: 'AccountRegistration',
        path: '/',
        componentPath: 'pages/accountRegistration',
        auth: false,
        exact: true,
     },
    ]
  },
  {
    component: "AuthLayout",
    path: "/createFreeProviderAccount/:action",
    auth: false,
    exact: true,
    childrens: [
      {
        component: 'AccountRegistration',
        path: '/',
        componentPath: 'pages/accountRegistration',
        auth: false,
        exact: true,
     },
    ]
  },
  {
    component: "AuthLayout",
    path: "/createFreeSeekerAccount/:action",
    auth: false,
    exact: true,
    childrens: [
      {
        component: 'AccountRegistration',
        path: '/',
        componentPath: 'pages/accountRegistration',
        auth: false,
        exact: true,
     },
    ]
  },

  {
    component: "AuthLayout",
    path: "/createFreeSeekerAccount",
    auth: false,
    exact: true,
    childrens: [
      {
        component: 'AccountRegistration',
        path: '/',
        componentPath: 'pages/accountRegistration',
        auth: false,
        exact: true,
     },
    ]
  },
  
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "ForgetPassword1",
        path: "/forgetPassword",
        name: "ForgetPassword",
        auth: false,
        exact: true,
      },
    ],
  },

  
];
export default routers;

