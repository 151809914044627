import React, { useEffect, useLayoutEffect, useState } from 'react'
import './style.scss'
import { getUserIp } from 'action/AuthAct'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const RedirectConfirmation = ({getUserIp}) => {

 const [redirect, setRedirect] = useState({status:false, message:''})
 const [countryCode, setCountryCode] = useState('')
 const [counter, setCounter] = useState(5)

  const  handleCheckServer = (code) => {
        let sub = window.location.host.split('.')[0]
        if(code === 'IN' && sub !== 'in'){
        setRedirect({status:true, message: 'You are browsing from India, redirecting you to Indian server . . . '})
        }else if( code !== 'IN'&& sub !== 'staffatom'){
        setRedirect({status:true, message: 'You are browsing from outside India, Redirecting to Global Server . . .'})
        }
          }
       
    const  handleFetchRegion =  () =>{
           fetch("https://geolocation-db.com/json/")
           .then(res => res.json())
           .then((data)=> {
             if(data.country_code){
              setCountryCode(data.country_code)
              handleCheckServer(data.country_code)
               return;
             }else{
              handleLoadRegion()
             }
             
           })
           .catch((err)=>{
            handleLoadRegion()
           })
         };
         const handleLoadRegion = async() =>{
                   await getUserIp()
                   .then((data)=>{
                   if(data['remote-address']){
                          fetch(`https://rdap.apnic.net/ip/${data['remote-address']}`)
                          .then(res => res.json())
                          .then((data)=> {
                            if(data.country){
                             setCountryCode(data.country)
                             handleCheckServer(data.country)
                              return;
                            }else{
                             handleFetchRegion()
                            }
                            
                          })
                          .catch((err)=>{
                            handleFetchRegion()
                           })
                   }
                   })
                   .catch((err)=>{
                    handleFetchRegion()
                   })
         }
       
     const  handleRedirect = (value=false) => {
       if(value === true){
         let sub = window.location.host.split('.')[0]
         if(countryCode === 'IN' && sub !== 'in'){
          window.location.href = 'https://in.staffatom.com/'
         }else if( countryCode !== 'IN'&& sub !== 'staffatom'){
          window.location.href = 'https://staffatom.com/'
         }
       }
       }
       
  
    useLayoutEffect(()=>{
      if(process.env.REACT_APP_BASE_REDIRECT === 'true'){
        // handleFetchRegion()
        handleLoadRegion()
      }
       
    },[])

    let interval;
    useLayoutEffect(()=>{
        if(redirect.status){
          interval = setInterval(()=>{
                  setCounter((prev)=>{
                    if(prev > 1) {
                        return prev - 1
                    }
                    clearInterval(interval)
                    return 0
                })
            },1000)
        }
        return () => clearInterval(interval);
    },[redirect])

    useEffect(()=>{
        if(counter < 1){
            handleRedirect(true)
            return;
        }
    },[counter])

    const handleRedirectCancel = () =>{
        clearInterval(interval)
        setRedirect({status:false, message:''})
      }

  return (<>
  {redirect.status === true &&
    <div className='redirect-global-box'>
         <div className='redirect-main-box'>
              <div className='redirect-message text-center'>
               {` ${redirect?.message} ${counter} sec`}
              </div>
                      <div className='redirect-buttons'>
                        <button className='redirect-button redirect-no' onClick={()=> handleRedirectCancel(false)}> CANCEL</button>
                        <button className='redirect-button' onClick={()=> handleRedirect(true)}>OK</button>
                      </div>
         </div>
    </div> }
    </>)
}



const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserIp,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(RedirectConfirmation);