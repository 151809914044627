import { subscriptionApi } from '../service/apiVariables';
import { addQuery } from '../service/helperFunctions';


export const getBillingPlans = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, subscriptionApi.getBillingType);
        api({ ...subscriptionApi.getBillingType })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                // reject(Toast({ type: 'error', message }));
            });
    });
};


export const getTimeZoneList = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, subscriptionApi.getTimeZoneList);
        api({ ...subscriptionApi.getTimeZoneList })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};


export const paymentCheckOutSession = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, subscriptionApi.paymentCheckOutSession);
        api({ ...subscriptionApi.paymentCheckOutSession})
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};


export const getTaxRegion = (query) => (dispatch, getState, { api, Toast }) => {
     return new Promise((resolve, reject) => {
      addQuery(query, subscriptionApi.getTaxRegion);
      api({ ...subscriptionApi.getTaxRegion })
        .then(({ data,message }) => {
          resolve(data); 
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
};