import React, { Component } from 'react';
import staffIqImg from "../../assets/svg/Frame 8.png"
import "./style.scss";
import Swiper from 'react-id-swiper';

export default class StaffIqValues extends Component {
    state = {
        params: {
            loop: true,
            spaceBetween: 30,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: false,
                type: 'bullets'
            },
            containerClass: 'swiper-container different'
        }
    }
    render() {
        let { params, } = this.state
        let companies = [
            {
                img: staffIqImg
            },
            {
                img: staffIqImg
            },
            {
                img: staffIqImg
            },
        ]
        // Load single page for Home

        const loadSingle = (item, index) => {
            return (
                <div className="corousel-outline" key={index}>
                    <div className="content">
                        <img src={item.img} alt="imageSource" />
                    </div>
                </div>
            )
        }
        // Load carousel for Additional Home
        const loadCarousel = (data) => {

            // data.map((item, index) => console.log(item))
            return (
                data.map((item, index) => loadSingle(item, index))
            )
        }
        return (
            <>
                <section className=" staff-iq-values text-center" id="staffIQ">
                    <div className="row justify-content-center m-0">
                        <div className="heading">
                            <h1>Staffatom Integration & Values</h1>
                            <p>
                                Staffatom automates and integrates the following interactions, communications and tasks between agencies, placement staff, and clients.
                            </p>
                        </div>
                        <div className="col-10 desk-view" style={{marginTop:"50px"}}>
                            <img src={staffIqImg} alt="staffIqImg" />
                        </div>

                    </div>
                </section>
                <div className="mobile-iq">
                    <div className="row m-0">
                        <div className="col-md-12 p-0">
                            <div className="clients">
                                <div className="swiper">
                                    <Swiper {...params} shouldSwiperUpdate>
                                        {loadSingle}
                                    </Swiper>
                                    <img src={staffIqImg} alt="staffIqImg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
