import React from "react";
import { Link } from 'react-router-dom';
import './footer.scss';
import { HashLink } from 'react-router-hash-link';
import JoinAS from "components/joinAs";
export class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showJoinAs: false,
    };
  }
  
  toggleJoinAs = () => {
    this.setState({ showJoinAs: !this.state.showJoinAs });
  };

  render() {
    
    return (
      <>
       {this.state.showJoinAs ? (
          <JoinAS show={this.state.showJoinAs} handleDialogBox={this.toggleJoinAs} email={this.state.email} />
        ) : null}
        <footer>
          <section id="footer">
            <div className="container">
              <div className="foot-blue">
                <div className="row">
                  <div className="col-md-4">
                    <div className="footer-title">
                      Staffatom
                    </div>
                    <div className="staffiq-text">
                      <p>Workforce Management Software Purpose Built for Staffing Agencies</p>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-3 mob-foot">
                        <p className="opacity-5 font-weight-bold">Platforms</p>
                        <ul>
                          <li>
                          <Link to="#">Payroll</Link> 
                          </li>
                          <li>
                          <Link to="#">Staff</Link> 
                          </li>
                          <li>
                          <Link to="#">Agencies</Link> 
                          </li>
                          <li>
                          <Link to="/overview#ourClients">Clients</Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3 mob-foot">
                        <p className="opacity-5 font-weight-bold">Company</p>
                        <ul>
                          <li>
                          <Link to="#">About Us</Link> 
                          </li>
                          <li>
                          <Link to="#">Customers</Link> 
                          </li>
                          <li>
                          <Link to="#">Careers</Link> 
                          </li>
                          <li>
                          <Link to="#">Security</Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3 mob-foot">
                        <p className="opacity-5 font-weight-bold">Tools</p>
                        <ul>
                          <li>
                          <Link to="/overview#planPricing">Pricing</Link> 
                          </li>
                          <li>
                          <Link to="/understand-app-gps-tracking">App Location usage</Link> 
                          </li>
                          <li>
                          <Link to="#">Resources</Link> 
                          </li>
                          <li>
                          <Link to="#">Worksheet</Link> 
                          </li>
                          <li>
                          <Link to="#">Support</Link> 
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3 get-start mob-foot">
                      <p className="opacity-5 font-weight-bold">Get Started</p>
                        <ul>
                          <li className="cursor-pointer" onClick={this.toggleJoinAs}>
                            Start a Free Trial
                          </li>
                          <li>
                          <Link to="#">Create a Business Case</Link> 
                          </li>
                          <li>
                            
                          <HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} to="/overview#contact-form">
                              Contact Us
                          </HashLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="social-media">
                      <div className="social-icons">
                        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><img src="/img/icons/facebook.svg" alt="facebook img"/></a> 
                      </div>
                      <div className="social-icons">
                        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><img src="/img/icons/twitter.svg" alt="twitter img"/></a> 
                      </div>
                      <div className="social-icons">
                        <a href="https://www.linkedin.com/company/" target="_blank" rel="noopener noreferrer"><img src="/img/icons/linkedin.svg" alt="linkedin img"/></a> 
                      </div>
                      <div className="social-icons">
                        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src="/img/icons/instagram.svg" alt="instagram img"/></a> 
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pb-4"> 
                  <div className="col-md-6">
                    <div className="">
                        <p className="copy-rights">
                        ©&nbsp;2022-2024&nbsp;&nbsp;Staff Atom LLC. &nbsp;All&nbsp;Rights&nbsp;Reserved.
                        </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <ul className="copy-right">
                      <li className="float-right pl-4">
                      <Link to="/disclaimer">Disclaimer </Link> 
                      </li>
                      <li className="float-right pl-4">
                      <Link to="/privacy-statement">Privacy</Link> 
                      </li>
                      <li className="float-right pl-4">
                      <Link to="/terms-and-conditions">Terms & Conditions</Link> 
                      </li>
                      {/* <li className="float-left">
                      <Link to="#">Sitemap</Link> 
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </footer>
      </>

    )
  }
}
