import axios from 'axios';

export const axiosInstance = axios.create({
	// baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

export const planFeatureLabels = {
	"Invoicing":"Auto-Smart Invoice",
	"Timesheet Management":"Timesheet Management",
	"Client portal ": "Client Management",
	"Staff App":"Staff App",
	"Staff":"Staff Management",
	"Reports":"Insightful Dashboard",
	"Audit Logs":"Audit Logs",
	"Time Off":"Time Off"
  }
  
  export const standardPlanFeatures = [
	'Work Shift Management',
	'Geo-Enabled Assignment',
	'Overtime Regulations',
	'Agency Skills Listing',
	'Recurring Shift Handling',
  ]

  export const standardClientPlanFeatures = [
	'Work Shift Scheduling',
	'Multi-Site Handling',
	'Multi-Agency Engagement',
	'Recurring Job Listing',
  ]

 export const statesList = [
	{ label: "[JK] - Jammu & Kashmir", value: "Jammu & Kashmir" },
	{ label: "[HP] - Himachal Pradesh", value: "Himachal Pradesh" },
	{ label: "[PB] - Punjab", value: "Punjab" },
	{ label: "[CH] - Chandigarh", value: "Chandigarh" },
	{ label: "[UT] - Uttarakhand", value: "Uttarakhand" },
	{ label: "[HR] - Haryana", value: "Haryana" },
	{ label: "[DL] - Delhi", value: "Delhi" },
	{ label: "[RJ] - Rajasthan", value: "Rajasthan" },
	{ label: "[UP] - Uttar Pradesh", value: "Uttar Pradesh" },
	{ label: "[BH] - Bihar", value: "Bihar" },
	{ label: "[SK] - Sikkim", value: "Sikkim" },
	{ label: "[AR] - Arunachal Pradesh", value: "Arunachal Pradesh" },
	{ label: "[NL] - Nagaland", value: "Nagaland" },
	{ label: "[MN] - Manipur", value: "Manipur" },
	{ label: "[MI] - Mizoram", value: "Mizoram" },
	{ label: "[TR] - Tripura", value: "Tripura" },
	{ label: "[ME] - Meghalaya", value: "Meghalaya" },
	{ label: "[AS] - Assam", value: "Assam" },
	{ label: "[WB] - West Bengal", value: "West Bengal" },
	{ label: "[JH] - Jharkhand", value: "Jharkhand" },
	{ label: "[OR] - Odisha", value: "Odisha" },
	{ label: "[CT] - Chattisgarh", value: "Chattisgarh" },
	{ label: "[MP] - Madhya Pradesh", value: "Madhya Pradesh" },
	{ label: "[GJ] - Gujarat", value: "Gujarat" },
	{ label: "[DD] - Daman & Diu", value: "Daman & Diu" },
	{ label: "[DN] - Dadra & Nagar Haveli", value: "Dadra & Nagar Haveli" },
	{ label: "[MH] - Maharashtra", value: "Maharashtra" },
	{ label: "[AP] - Andhra Pradesh", value: "Andhra Pradesh" },
	{ label: "[KA] - Karnataka", value: "Karnataka" },
	{ label: "[GA] - Goa", value: "Goa" },
	{ label: "[LD] - Lakshadweep", value: "Lakshadweep" },
	{ label: "[KL] - Kerala", value: "Kerala" },
	{ label: "[TN] - Tamil Nadu", value: "Tamil Nadu" },
	{ label: "[PY] - Puducherry", value: "Puducherry" },
	{ label: "[AN] - Andaman & Nicobar Islands", value: "Andaman & Nicobar Islands" },
	{ label: "[TL] - Telangana", value: "Telangana" },
	{ label: "[AD] - Hyderabad GST Commissionerate", value: "Hyderabad GST Commissionerate" },
	{ label: "[LA] - Kurnool GST Commissionerate", value: "Kurnool GST Commissionerate" }
  ];
  
  

export const stateCodeMapping = {
	"01": "JK - Jammu & Kashmir",
	"02": "HP - Himachal Pradesh",
	"03": "PB - Punjab",
	"04": "CH - Chandigarh",
	"05": "UT - Uttarakhand",
	"06": "HR - Haryana",
	"07": "DL - Delhi",
	"08": "RJ - Rajasthan",
	"09": "UP - Uttar Pradesh",
	"10": "BH - Bihar",
	"11": "SK - Sikkim",
	"12": "AR - Arunachal Pradesh",
	"13": "NL - Nagaland",
	"14": "MN - Manipur",
	"15": "MI - Mizoram",
	"16": "TR - Tripura",
	"17": "ME - Meghalaya",
	"18": "AS - Assam",
	"19": "WB - West Bengal",
	"20": "JH - Jharkhand",
	"21": "OR - Odisha",
	"22": "CT - Chhattisgarh",
	"23": "MP - Madhya Pradesh",
	"24": "GJ - Gujarat",
	"25": "DD - Daman & Diu",
	"26": "DN - Dadra & Nagar Haveli",
	"27": "MH - Maharashtra",
	"28": "AP - Andhra Pradesh",
	"29": "KA - Karnataka",
	"30": "GA - Goa",
	"31": "LD - Lakshadweep",
	"32": "KL - Kerala",
	"33": "TN - Tamil Nadu",
	"34": "PY - Puducherry",
	"35": "AN - Andaman & Nicobar Islands",
	"36": "TL - Telangana",
	"37": "AD - Hyderabad GST Commissionerate",
	"38": "LA - Kurnool GST Commissionerate"
};

export function validateGSTIN(GSTIN) {
	const GSTIN_REGEX = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z][1-9A-Za-z]Z[0-9a-zA-Z]$/;
	const CHECKSUM_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  
	GSTIN = GSTIN.trim();
  
	if (!GSTIN) {
	  return "GSTIN is empty";
	}
  
	if (!GSTIN.match(GSTIN_REGEX)) {
	  return "GSTIN doesn't match the pattern";
	}
  
	const checkSum = generateCheckSum(GSTIN.slice(0, -1), CHECKSUM_CHARS);
	const isValid = GSTIN[GSTIN.length - 1] === checkSum;
  
	if (isValid) {
	  return `GSTIN is valid`;
	} else {
	  return `GSTIN is not valid`;
	}
  }
  
  function generateCheckSum(GSTIN, CHECKSUM_CHARS) {
	if (!GSTIN) {
	  throw new Error("GSTIN supplied for checkdigit calculation is null");
	}
  
	const Mod_ = CHECKSUM_CHARS.length;
	const cpChars = CHECKSUM_CHARS.split('');
  
	let sum = 0;
	let factor = 2;
  
	for (let i = GSTIN.length - 1; i >= 0; i--) {
	  const codePoint = cpChars.indexOf(GSTIN[i]);
	  const digit = factor * codePoint;
	  factor = factor === 2 ? 1 : 2;
	  sum += Math.floor(digit / Mod_) + (digit % Mod_);
	}
  
	const checkCodePoint = (Mod_ - (sum % Mod_)) % Mod_;
	return cpChars[checkCodePoint];
  }