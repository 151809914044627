import React from "react";
import Swiper from 'react-id-swiper';
import './ourClients.scss';
export class OurClients extends React.Component {

    state = {
        params: {
            slidesPerView: 5,
            spaceBetween: 30,
            slidesPerGroup: 3,
            loop: true,
            loopFillGroupWithBlank: false,
            freeMode: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },

        }
    }


    render() {
        let { params, } = this.state
        let companies=[{description:"On-site Staff Tracking",image:"/icons/staffimg.svg"},
                       {description:"Invoicing Software",image:"/icons/invoice.svg"},
                       {description:"Client Management",image:'/icons/clientimg.svg'},
                       {description:"Business Process Automation",image:'/icons/business.svg'}]
        // Load single page for Home
        const loadSingle = (item, index) => {
            return (
                <div className="logo-outline" key={index}>
                    <img className="logo" src={`/img/${item.image}`} alt="image" />

                    <div className="description">
                        {item.description}
                    </div>
                </div>
            )
        }



        // Load carousel for Additional Home
        const loadCarousel = (data) => {

            // data.map((item, index) => console.log(item))
            return (
                data.map((item, index) => loadSingle(item, index))
            )
        }



        return (
            <section id="ourClients">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clients">
                                <div className="heading">
                                <h1>Staff Can Complement All These</h1>
                                <p>
                                    Saving your subscription fees and time jumping back and forth
                                </p>
                                </div>
                                 <div className="swiper">
                                 {/* <div className="card"> */}
                                    <Swiper {...params}
                                        shouldSwiperUpdate
                                        showsPagination={false}
                                        breakpoints={{
                                            577: {
                                                slidesPerView: 4,
                                            },
                                            767: {
                                                slidesPerView:4,
                                            },
                                        }}
                                    >
                                        {loadCarousel(companies)}
                                    </Swiper>  


                                    {/* </div> */}
                                </div>

                            </div>
                        </div>
                        <div className="pl-5 col-12 row align-items-center clients-mob">
                            <div className="col-12 row d-flex justify-content-center">
                                <h1 className="title">Staff Can Complement All These</h1>
                                <p>
                                    Saving your subscription fees and time jumping back and forth
                                </p>
                            </div>
                            {companies.slice(0, 6).map((item) =>
                                <>
                                    <div className="col-6 text-center">
                                        <img src={`/img/${item.image}`} />
                                    </div>
                                    <div className="description">
                                        {item.description}
                                    </div>
                                </>
                            )}
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}
