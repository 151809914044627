
import { lazy } from 'react';

import { Overview } from './landing/index';
const Subscription = lazy(() => import('./subscription/index').then(module => ({ default: module.Subscription })));
const Login = lazy(() => import('./Auth/Login').then(module => ({ default: module.Login })));
const ForgetPassword1 = lazy(() => import('./Auth/ForgetPassword1').then(module => ({ default: module.ForgetPassword1 })));
const Terms = lazy(() => import('./terms/index').then(module => ({ default: module.Terms })));
const Privacy = lazy(() => import('./privacy/index').then(module => ({ default: module.Privacy })));
const Disclaimer = lazy(() => import('./disclaimer/index').then(module => ({ default: module.Disclaimer })));
const AccountRegistration = lazy(() => import('./accountRegistration/index').then(module => ({ default: module.AccountRegistration })));
const AppLocationUsage = lazy(() => import('./appLocationUsage/index').then(module => ({ default: module.AppLocationUsage })));

export { Overview, Subscription, Login, ForgetPassword1, Terms, Privacy, Disclaimer, AccountRegistration, AppLocationUsage };

