import React from "react";
import agency_benefits from '../../assets/svg/agency_benefits.svg'
import client_benefits from '../../assets/svg/client_benefits.svg'
import './style.scss'

const Benefits = (props) => {
    return (
        <div className="benefits-container">
            <h3 className="feature-heading">Benefits <span className="feature-badge">{props.featureData?.portal === 'agency' ? 'Agency' : 'Client'}</span></h3>
            <div className="features-grid">
                <div className="features-left">
                    <p className='image-heading'>Share a link to your design files or prototypes, and get feedback in context.</p>
                    <img className='features-image' src={props.featureData?.portal === 'agency' ? agency_benefits : client_benefits} />
                </div>
                <div className="features-right">
                    <div className="content-section">
                        <div className="content-heading">
                            Tailored Recruitment Solutions:
                        </div>
                        <ul>
                            <li>Crafting customized staffing solutions to meet unique industry needs.</li>
                            <li>Dedicated teams sourcing the perfect talent for your requirements.</li>
                        </ul>
                    </div>
                    <div className="content-section">
                        <div className="content-heading">
                            Quality Talent Pool:
                        </div>
                        <ul>
                            <li>Access top talent.</li>
                            <li>Rigorous screening for quality placements.</li>
                        </ul>
                    </div>
                    <div className="content-section">
                        <div className="content-heading">
                            Swift Solutions, Personalized Support:
                        </div>
                        <ul>
                            <li>Rapid response to staffing needs.</li>
                            <li>24/7 support, personalized service, and timely updates for a seamless experience.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefits;