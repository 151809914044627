import React from "react";
import './freeTrial.scss';
import { Link } from 'react-router-dom';
import { NormalButton } from "../../../common";
// import icon1 from '../../../../assets/images/icons/icon1.svg';
export class FreeTrial extends React.Component {
    constructor(props) {
		super(props)
	  }
    state = {}


    render() {
        return (
            <section id="freeTrial" className="pt-0">
                <div className="container-fluid bg">
                    <div className="row justify-content-center">
                        <div className="">
                            <div className="clients">
                                <h1>Get Free Staffatom For 30-Days With All Features </h1>
                                    <NormalButton
                                        className="btn-blue"
                                        label="Start Free Trial"
                                        onClick={this.props.handleDialogBox}
                                    />
                                <ul className="desk-top">
                                    <li>
                                        <span className="material-icons">
                                            done
                                            </span>
                                            No Credit Card Needed
                                        </li>
                                    <li>
                                        <span className="material-icons">
                                            done
                                            </span>
                                            Agency Portal
                                        </li>
                                    <li>
                                        <span className="material-icons">
                                            done
                                            </span>
                                            Staff Portal
                                        </li>
                                    <li>
                                        <span className="material-icons">
                                            done
                                            </span>
                                            Client Portal
                                        </li>
                                    <li>
                                        <span className="material-icons">
                                            done
                                            </span>
                                            Smart Invoicing
                                        </li>
                                </ul>

                                <ul className="mobile-view container-fluid">
                                    <li>
                                        <span className="material-icons"> done </span>
                                        Agency Portal
                                        </li>
                                    <li>
                                        <span className="material-icons">  done </span>
                                        Staff Portal
                                        </li>
                                    <li>
                                        <span className="material-icons">  done  </span>
                                        Invoicing
                                        </li>
                                    <li>
                                        <span className="material-icons">
                                            done
                                            </span>No Credit card needed
                                        </li>
                                    <li>
                                        <span className="material-icons">
                                            done
                                            </span>
                                            Unlimited Clients & staff
                                        </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
