import { generateQuery } from "./helperFunctions";

export const authApi = {
  adminLogin: {
    api: "v1/login",
    method: "post",
    baseURL: "auth",
  },
  otpLogin: {
    api: "v1/otpLogin",
    method: "post",
    baseURL: "auth",
  },
  forgetPassword: {
    url: "v1/sendOtp",
    method: "put",
    baseURL: "auth",
    query: {
      emailId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  resetPassword: {
    api: "v1/resetPassword",
    method: "post",
    baseURL: "auth",
  },
  getIp: {
    api: "v1/getIP",
    method: "get",
    baseURL: "job",
  },
};

export const subscriptionApi = {
  postAdd: {
    api: 'pay/payment',
    method: 'post',
    baseURL: 'contact',
  },
  postFreeAcc: {
    api: 'agency/createFreeAccount',
    method: 'post',
    baseURL: 'agency',
  },

  postAddBilling: {
    api: 'pay/billing',
    method: 'post',
    baseURL: 'agency',
  },

  postPayment: {
    api: 'pay/payment',
    method: 'put',
    baseURL: 'agency',
  },

  postAddAgency: {
    api: 'agency/add',
    method: 'post',
    baseURL: 'agency',
  },
  postAddClient: {
    api: 'client/add',
    method: 'post',
    baseURL: 'client',
  },
  editSubscriptionPlan: {
    api: "agency/updateSubscription",
    method: "put",
    baseURL: "agency",
  },
  getSecret: {
    api: "pay/secret",
    method: "post",
    baseURL: "agency",
  },
  getUsers: {
    url: "subscription/getAll",
    method: "get",
    baseURL: "contact",
    query: {
      filter: null,
      region: null,
      page: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getUsersById: {
    url: "subscription/getById",
    method: "get",
    baseURL: "contact",
    query: {
      id: null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  
  // getByCode: {
  //   url: "discount/getByCode",
  //   method: "get",
  //   baseURL: "contact",
  //   query: {
  //     code: null
  //   },
  //   get api() {
  //     return this.url + generateQuery(this.query);
  //   },
  //   set addQuery({ key, payload }) {
  //     this.query[key] = payload;
  //   },
  // },
  deleteUsersById: {
    url: "/subscription/delete",
    method: "get",
    baseURL: "subscription",
    query: {
      id: null,
      deleteFlag: null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getBillingType: {
    url: "subscription/getBillingType",
    method: "get",
    baseURL: "subscription",
    query: {
      type:2
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getTimeZoneList: {
    url: "subscription/getTimeZoneList",
    method: "get",
    baseURL: "subscription",
    query: {
       timezone: ["America", "Asia", "US", "Pacific"],
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },

getTaxRegion: {
  url: "tax/region",
  method: "get",
  baseURL: "job",
  query: {
    code:''
  },
  get api() {
    return this.url + generateQuery(this.query);
  },
  set addQuery({ key, payload }) {
    this.query[key] = payload;
  },
},

 paymentCheckOutSession: {
  url: "payment/checkoutSession",
  method: "post",
  baseURL: "subscription",
  query: {
     paymentReq:''
   },
   get api() {
     return this.url + generateQuery(this.query);
   },
   set addQuery({ key, payload }) {
     this.query[key] = payload;
   },
},
};

export const subscriptionApiForClient = {
  postClientFreeAccount: {
    api: 'client/createFreeAccount',
    method: 'post',
    baseURL: 'client',
  },
}

export const userManagementApi = {
  getAllUsers: {
    url: "getUserList",
    method: "get",
    baseURL: "User",
    query: {
      filter: "all",
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  addCoAdmin: {
    api: "createUser",
    method: "post",
    baseURL: "User",
  },

  updateCoAdmin: {
    api: "updateUser",
    method: "put",
    baseURL: "User",
  },

  getUserById: {
    url: "getUserById",
    method: "get",
    baseURL: "User",
    id: 1,
    get api() {
      return this.url + "?userId=" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },

  archiveUser: {
    api: "archiveUser",
    method: "put",
    baseURL: "User",
  },

  enablePermission: {
    api: "enablePermission",
    method: "put",
    baseURL: "User",
  },

  activeStatus: {
    api: "activeStatus",
    method: "put",
    baseURL: "User",
  },
};

export const contentMaganementApi = {
  getAllAgencies: {
    url: "content/news/news/get",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  addNews: {
    api: "content/news/news/add",
    method: "post",
    baseURL: "Content",
  },
  updateNews: {
    api: "content/news/news/update",
    method: "put",
    baseURL: "Content",
  },
  getAllNews: {
    url: "content/news/news/get",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getNewsById: {
    url: "content/news/news",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteNewsById: {
    url: "content/news/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
};


export const stripeSubscriptionApi = {
  getAllPricingPlan: {
    url: "stripe/subscription/getAllPricingPlan",
    method: "get",
    baseURL: "subscription",
    query: {
      active:true
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getPricingPlanById: {
    url: "stripe/subscription/getPricingPlanById",
    method: "get",
    baseURL: "subscription",
    query: {
      priceId:null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  payment: {
    url: "stripe/subscription/payment",
    method: "post",
    baseURL: "subscription",
    query: {
      priceId:null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  checkout: {
    url: "stripe/subscription/checkoutSession",
    method: "post",
    baseURL: "subscription",
    query: {
      email:'',
      currency:'',
      planName:'',
      quantity :0,
      unitAmount:0
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  }
}

export const razorPaySubscriptionApi = {
  checkout: {
    url: "razorpay/subscription/checkoutSession",
    method: "post",
    baseURL: "subscription",
    query: {
      currency:'',
      unitAmount:0
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
}