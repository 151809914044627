import React from 'react';
import { NormalButton } from '../../../common';
import SimpleReactValidator from "simple-react-validator";
import rightIcon from '../../../../assets/svg/arrow_forward.svg';
import doneIcon from '../../../../assets/svg/mdi_done.svg';
import molecular from '../../../../assets/images/Group (8).png'
import closeIcon from '../../../../assets/svg/close.svg'

import './banner.scss';
import JoinAS from 'components/joinAs';


export class Banner extends React.Component {
	constructor(props) {
	super(props);
	this.state={
		videoBox:true,
		email:'',
		show:false
	}
	this.validator = new SimpleReactValidator({
		validators: {
		  email: {
			message: "must be a valid mobile number or Email.",
			rule: (val, params, validator) => {
			  return (
				validator.helpers.testRegex(val, /^[0-9]{10}$/) ||
				validator.helpers.testRegex(
				  val,
				  /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/
				)
			  );
			},
			messageReplace: (message, params) =>
			  message.replace("", this.helpers.toSentence(params)),
			required: true,
		  },
		  element: (message) => (
			<span className="error-message font-md">{message}</span>
		  ),
		  autoForceUpdate: this,
		},
	  });
	}	
	setVideoBox=()=>{
		this.setState({
			videoBox:!this.state.videoBox
		})
	}
	handleInput = ({ target: { name, value } }) => {
			this.setState({
				email:value
			})
	};
	handleSubmit=()=>{
		let {email}=this.state
		if (this.validator.allValid()) {
			// window.location.href=`/freeAccount/${email}`
			this.handleDialogBox()
		}
		else{
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}
	handleDialogBox = () =>{
		this.setState({
			show : !this.state.show
		})
	  }
	render() {
		let{videoBox,email}=this.state
		return (
			<section id='section-banner'>
				{this.state.show ? <JoinAS show={this.state.show} handleDialogBox={this.handleDialogBox} email={this.state.email}/> : null}
				
				<div className='container-fluid'>
					<div className='row'>
						<div className='bg'>
							<h1>Workforce Management Software Purpose Built for Staffing Agencies</h1>
							<p className='mb-3'>
								Staffatom automates scheduling, time keeping, shift verification, invoicing, credential management & more, working the way agencies operate and allowing agencies to replace multiple subscriptions and other processes.
							</p>
							{videoBox?
							<>
								{/* <div className='demo'>
								<NormalButton rightIcon='play_arrow' onClick={this.setVideoBox} className='btn-white' label='Demo video' />
								</div>
								<div className='gif-div'>
									<img src='/img/gif/animation.gif' alt='animation' />
								</div> */}

							
							<div className="row justify-content-center">
								<div className="col-12">
									<div className='input-group custom-input-group get-start'>
										<input
											type='text'
											value={email.toLowerCase()}
											className={`form-control custom-banner-input ${(this.validator.message('Email', email, 'required|email'))?'border border-danger':''}`}
											placeholder='Enter your email'
											aria-label='Enter your email'
											onChange={this.handleInput}
											aria-describedby='basic-addon2'
										/>
										<div className='input-group-append' onClick={this.handleSubmit}>
											<button className="btn-trans">
												<span className='input-group-text custom-input-append' id='basic-addon2'>
													Free Trial <img className='px-2' src={rightIcon} alt='right' />
												</span>
											</button>
										</div>
									</div>
								</div>
							</div>							
							<div className='form-group mobile-form my-4 w-100 px-5'>
								<input
									placeholder='ENTER YOUR EMAIL'
									value={email.toLowerCase()}
									onChange={this.handleInput}
									className='form-input shadow-sm w-100 mb-3'
								/>
								<button onClick={this.handleSubmit} className='banner-button shadow-sm mb-3 text-white w-100 d-flex align-items-center justify-content-center'>
									Get Started
									<img className='right-icon' src={rightIcon} alt='right' />
								</button>
							</div>
							<div className='d-flex content-wrap justify-content-center align-items-center trail'>
								<div className='done d-flex align-items-start'>
									<img src={doneIcon} alt='done' />
									<p className='done-text'>No Credit Card Required</p>
								</div>
								<div className='done d-flex align-items-start desktop'>
									<img src={doneIcon} alt='done' />
									<p className='done-text'>Full Application Access </p>
								</div>
								<div className='done d-flex align-items-start'>
									<img src={doneIcon} alt='done' />
									<p className='done-text'>No Restriction</p>
								</div>
								<div className='done d-flex align-items-start mobile'>
									<img src={doneIcon} alt='done' />
									<p className='done-text'>Full application access </p>
								</div>
							</div>
							</>
							:
							<div className={`video-box ${videoBox?'fade-out':'fade-in'} shadow-lg my-5`}>
						 		<div className="d-flex justify-content-end">
									<img className="close-icon m-2" onClick={this.setVideoBox} src={closeIcon} alt="close icon"/>
								</div>
								<div className="embed-responsive embed-responsive-16by9">
									<iframe title='youtube' className='embed-responsive-item' src="https://www.youtube.com/embed/tBtsUgD6FAE?autoplay=1&rel=0&theme=light&controls=0" width="559" height="315"></iframe>
								</div>
							</div>
							}
							
						</div>
					</div>
				</div>
				<div className='bg-decorate'>
					<img src='/img/molecular-structure.png' alt='animation' className='landing-bg-left'/>
				</div>
				<div className='bg-decorate-right'>
					<img src={molecular} alt='animation' className='landing-bg-right'/>
				</div>
				

			</section>
		);
	}
}
