import React from "react";
// import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {  NormalButton } from '../../common';
import menuIcon from '../../../assets/svg/mdi_menu.svg'
import closeIcon from '../../../assets/svg/close.svg'
import { history } from "../../../service/helpers";
import './header.scss';
import JoinAS from "components/joinAs";
export class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			url:"",
			responsiveMenu:false,
			show : false
		}
		this.toggleMenu = this.toggleMenu.bind(this);

	  }
	
	componentDidMount(){
	}
	updateUrl = ()=>{
		this.toggleMenu()
		let url = window.location.href.split("#")[1];
		setTimeout(this.setState({url},()=>{
            
        }), 1000);
		
	}
	toggleMenu=()=>{
		this.setState({
			responsiveMenu:!this.state.responsiveMenu
		});
	}
	handleDialogBox = () =>{
		this.setState({
			show : !this.state.show
		})
	  }

	render() {
		let {responsiveMenu}=this.state
		const {url}=this.state;
		
		const responsiveMenuBar=()=>{
			return(
			<>
				<div className="sidebar">
					<div className="d-flex justify-content-end">
						<img className='close-icon' onClick={this.toggleMenu} src={closeIcon} alt="closeIcon"/>
					</div>
					<ul>
						<li className="nav-item" key='section-banner' onClick={this.updateUrl}>
							<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} onClick={this.updateUrl} to="#section-banner" className={(url==='section-banner'?'active ':'')+" nav-link"} >
									Overview
							</HashLink>
						</li>
						<li className="nav-item" key='staffiq' onClick={this.updateUrl}>
							<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} onClick={this.updateUrl} to="/overview#staffIQ"  className={(url==='staffIQ'?'active ':'')+" nav-link"}>
								How Staffatom Works
							</HashLink>
						</li>	
						<li className="nav-item" key='features' onClick={this.updateUrl}>
							<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} onClick={this.updateUrl} to="/overview#remote-Working"  className={(url==='remote-Working'?'active ':'')+" nav-link"}>
								Features
							</HashLink>
						</li>
						<li className="nav-item" key='pricing' onClick={this.updateUrl}>
							<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} onClick={this.updateUrl} to="/overview#planPricing"  className={(url==='planPricing'?'active ':'')+" nav-link"} >
								Pricing
							</HashLink>
						</li>
						<li className="nav-item" key='contact-us' onClick={this.updateUrl}>
							<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} onClick={this.updateUrl} to="/overview#contact-form" className={(url==='contact-form'?'active ':'')+" nav-link"} >
								Contact Us
							</HashLink>
						</li>
						<li className="nav-item" key='auth-login' onClick={this.updateUrl}>
							<HashLink smooth target="_blank" onClick={this.updateUrl} to="/auth/login" className={(url==='/auth/login'?'active ':'')+" nav-link"} >
								Sign In
							</HashLink>
						</li>
					</ul>
				</div>
			</>)
		}

		const loadChildren = () => {
			return (
				<>
					<li className="nav-item" key='section-banner' onClick={this.updateUrl}>
						<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} to="/overview#section-banner" className={(url==='section-banner'?'active ':'')+" nav-link"} >
							Overview
						</HashLink>
							<div className="nav-underbar-overview">
							<span style={!url || url == 'section-banner'? {visibility:'visible'}:{visibility:'hidden'}}></span>
							<span style={!url || url == 'section-banner'? {visibility:'visible'}:{visibility:'hidden'}}></span>
							<span style={!url || url == 'section-banner'? {visibility:'visible'}:{visibility:'hidden'}}></span>
							<span style={
							url == 'staffIQ' && window.screen.width < 1400 && window.devicePixelRatio > 1.44 ? {width:'155px',left:'109%',animationName:'shake-works'}: (url == 'staffIQ' && window.screen.width < 1400 && window.devicePixelRatio <= 1.44) || (url == 'staffIQ' && window.screen.width >= 1400) ? {width:'155px',left:'114%',animationName:'shake-works'}: url == 'remoteWorking' && window.screen.width < 1400 && window.devicePixelRatio > 1.44 ? {width:'65px',left:'313%',animationName:'shake-features'}: (url == 'remoteWorking' && window.screen.width < 1400 && window.devicePixelRatio <= 1.44) || (url == 'remoteWorking' && window.screen.width >= 1400) ? {width:'65px',left:'304.5%',animationName:'shake-features'}: url == 'planPricing' && window.screen.width < 1400 && window.devicePixelRatio > 1.44 ? {width:'55px',left:'406%',animationName:'shake-pricing'}: (url == 'planPricing' && window.screen.width < 1400 && window.devicePixelRatio <= 1.44) || (url == 'planPricing' && window.screen.width >= 1400) ? {width:'55px',left:'398%',animationName:'shake-pricing'}: !url || url == 'section-banner' && window.screen.width < 1400 && window.devicePixelRatio > 1.44 ? {width:'70px',left:'10%'}: (!url || url == 'section-banner' && window.screen.width < 1400 && window.devicePixelRatio <= 1.44) || (!url || url == 'section-banner' && window.screen.width >= 1400) ? {width:'75px',left:'10%'}: url == 'contact-form' && window.screen.width < 1400 && window.devicePixelRatio > 1.44 ? {width:'80px',left:'485%'} : (url == 'contact-form' && window.screen.width < 1400 && window.devicePixelRatio <= 1.44) || (url == 'contact-form' && window.screen.width >= 1400) ? {width:'90px',left:'480%'} : {display:'none'}
						    }></span>
							</div>
					</li>
					<li className="nav-item" key='staffIQ' onClick={this.updateUrl}>
						<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} to="/overview#staffIQ"  className={(url==='staffIQ'?'active ':'')+" nav-link"}>
							How Staffatom Works
						</HashLink>
					</li>
					<li className="nav-item" key='remoteWorking' onClick={this.updateUrl}>
						<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} to="/overview#remoteWorking"  className={(url==='remoteWorking'?'active ':'')+" nav-link"}>
							Features
						</HashLink>
					</li>
					<li className="nav-item" key='planPricing' onClick={this.updateUrl}>
						<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} to="/overview#planPricing"  className={(url==='planPricing'?'active ':'')+" nav-link"} >
							Pricing
						</HashLink>
					</li>
					<li className="nav-item" key='contact-form' onClick={this.updateUrl}>
						<HashLink scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} to="/overview#contact-form" className={(url==='contact-form'?'active ':'')+" nav-link"} >
							Contact Us
						</HashLink>
						{url == 'contact-form' && <div className="nav-underbar-contact">
							<span></span>
							<span></span>
							<span></span>
							</div>}
					</li>
				</>
			);
		}
		return (
			<>	
				<header id="header" className="fixed-top">
				{this.state.show ? <JoinAS show={this.state.show} handleDialogBox={this.handleDialogBox} email={this.state.email}/> : null}
					<div className="container-fluid-lg pr-0">
						<nav className="navbar navbar-expand-lg bg-light navbar-light ">
							<HashLink smooth to="#section-banner" className="navbar-brand">
								<img className="header-logo" src="/img/logo.png" alt="logo" />
							</HashLink>
							<HashLink smooth to={`#freeTrial`} className="nav-link active custom-mob-link" >
									GET STARTED
							</HashLink>
							<button className="navbar-toggler bg-purple" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
								<img src={menuIcon} onClick={this.toggleMenu} alt="menuIcon"/>
							</button>
							<div className="collapse navbar-collapse" id="collapsibleNavbar">
								<ul className="navbar-nav">

									{loadChildren()}
								</ul>
								{
									<div className="loginBtn">
										<li className="nav-item">
											<HashLink target="_blank" smooth to={`/auth/login`} className="nav-link">
												Sign In
											</HashLink>
										</li>
										
										
										<NormalButton
											className="btn-blu"
											label="Get Started"
											onClick={this.handleDialogBox}
										/>
										
										
									</div>
										
								}
							</div>
						</nav>
					</div>
				</header>
				{responsiveMenu?responsiveMenuBar():''}
			</>
		)
	}
}