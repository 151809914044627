import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "./service/helpers"
import 'react-notifications/lib/notifications.css';
import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// let it2 ='pk_test_51IOlAALjfN1bLLrdHsZib0xNB1HbMimq2jyEMs5KwTLrWOOuxdkxY5dQ03UgWl9tCeT0XBnEKEY6Rwb4UvZIy2fF00sPXF8dnh';
// const stripePromise = loadStripe(it2);
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
		<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
