import React from 'react';
import './style.scss';

const ConfirmationBox = (props) => {

    const {message, handleOk, handleCancel} = props

    return (
      <div className='confirm-global-box'>
               <div className='confirm-main-box'>
                      <div className='confirm-message text-center'>
                            {message}
                       </div>
                   <div className='confirm-buttons'>
                     <button className='confirm-button confirm-no' onClick={handleCancel}> CANCEL</button>
                     <button className='confirm-button' onClick={handleOk}>YES</button>
                   </div>
               </div>
      </div>
    )
  }

export default ConfirmationBox