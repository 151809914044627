import React from "react";
import './planPricing.scss';
// import Swiper from 'react-id-swiper';
import { NormalButton } from "../../../common";
// import icon1 from '../../../../assets/images/icons/icon1.svg';
import { api } from '../../../../service/api';
import { Toast } from '../../../../service/toast';
// import Geocode from "react-geocode";
import { Link } from "react-router-dom";
// import { Navigation, Pagination, Scrollbar } from 'swiper';
// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
export class PlanPricing extends React.Component {
    constructor(props) {
        super(props);
        this.getSubscriptionList = this.getSubscriptionList.bind(this);
        
        this.state = {
            loading:false,
            subscriptionList:[],
            activeTab:"Yearly",
            geoPosition:{
                lat:'',
                long:''
            },
            image: '',
            // previewImage: '',
            params: {
                // slidesPerView: 4,
                spaceBetween: 10,
                // slidesPerGroup: 3,
                // loop: true,
                loopFillGroupWithBlank: true,
                freeMode: true,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false
                },
                pagination: {
                  el: '.swiper-pagination',
                  clickable: false
                },
    
            }
          };
    }
    activeTab=(tab)=>{
        this.setState({
            activeTab:tab
        },()=>{
            this.getSubscriptionList(tab === "Monthly" ? 1 : 2)
            
        })
    }

    
    
    getSubscriptionList(type){
        let result =  new Promise((resolve, reject) => {
            
            api({method:'get',api:'subscription/getBillingType?type='+type,status:false,
            baseURL:'contact'})
               .then(({ data }) => {
                   
                   this.setState({
                       subscriptionList:data.subscriptionMasterList
                   })
                  resolve(data);
               })
               .catch(({ message }) => {
                  reject(Toast({type:'error',message}));
               });
         });
         if(result.status){
             
            //  Toast({type:'success',message})
         }
        }
       
      componentDidMount() {
        this.getSubscriptionList(2)
  
      }

    render() {
        let {subscriptionList ,activeTab}=this.state
        // const fetchClient=(features)=>{
        //     console.log("THIS HITS",features)
        //     if (features.filter((e) => 
        //         {  
        //            return e.value === 'Invoicing '
        //         }
        //      )); 
    
        // }
        const loadSingle = (item, index,len) => {
            
            let featuresList = item.features;
            return (
                
                <div className="plan">
                    
                    { item.popular===0?<div className="special-bar transparent">
                        
                    </div>:item.popular===1?
                    <div className="special-bar blue">
                        Popular plan
                    </div>
                    :
                    <div className="special-bar orange">
                        Recommended plan
                    </div>
                    }
                    
                    <div className={`box card ${item.popular===0?'green':item.popular===1?'blue':'orange'}`}>
                        <p className="title card-title-space text-center">{item.planName}</p>
                        <p className="title amount text-center"> 
                        {this.state.activeTab==="Monthly"?
                            <span>{ item.planPrices?item.planPrices[0].amount:''} {item.planPrices?item.planPrices[0].currency === "usd" ?'$': item.planPrices?item.planPrices[0].currency ==="INR"?'₹':item.planPrices[0].currency !== null && item.planPrices[0].currency.toUpperCase():'':''} /Mth</span>
                            :
                            <span>{item.planPrices?item.planPrices[1].amount:''} {item.planPrices?item.planPrices[1].currency === "usd"?'$': item.planPrices?item.planPrices[1].currency ==="INR"?'₹':item.planPrices[1].currency !== null && item.planPrices[1].currency.toUpperCase():'':''} /Yr</span>
                        }
                        </p>
                        <div className="textDesc">
                            <p className="black text-center">{item.description}</p>
                        </div>
                        <p className=" black sub-title text-center">
                            {item.userRange?item.userRange.min:''}-{item.userRange?item.userRange.max:''} USERS {item.features&&featuresList.map(e =>e.value.includes('Client')?'+ Unlimited Users':'')}
                        </p>
                        {item.features?
                        <ul className="feature-list">
                        {featuresList.map((item, index) => {
                            return <>
                                        <li> 
                                            <span className="material-icons green">
                                                {item.status?'done':'close'}
                                            </span>
                                            {item.value}
                                        </li>
                                    </>
                            })
                        }
                        </ul>:''}
                        <Link to={`/subscription/${item.id}/${activeTab}`}>
                            <NormalButton
                            rightIcon="arrow_forward"
                            className="btn-blue"
                            label="Select Plan"
                        />
                        </Link>
                    </div>
                </div>
                
            )
        }

        // Load carousel 
        const loadCarousel = (data) => {
            //console.log(data.length,"sad")
            // data.map((item, index) => console.log(item))
            return data.map((item, index) => loadSingle(item, index,data.length))
            
        }


        return (
            <section id="planPricing" >
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-12">
                            <div className="clients">
                                <h1>Plan Pricing</h1>
                                <div className="planning d-flex justify-content-center">
                                    <div onClick={()=>this.activeTab("Monthly")} className={`yearPlan text-center ${this.state.activeTab==="Monthly"?'orange':''}`} >
                                        Monthly
                                    </div>
                                    <div onClick={()=>this.activeTab("Yearly")} className={`yearPlan text-center ${this.state.activeTab==="Yearly"?'orange':''}`}>
                                        Yearly
                                    </div>
                                </div>
                                {/* <div className="swiper"> */}
                                <div className="subscription-loading">
                                    {subscriptionList.length?
                                    //  <Swiper {...params}
                                    //         shouldSwiperUpdate
                                    //         scrollbar={{
                                    //             hide: true,
                                    //           }}
                                    //         showsPagination={false}
                                    //         breakpoints={{
                                    //             577: {
                                    //                 slidesPerView: 4,
                                    //             },
                                    //             767: {
                                    //                 slidesPerView: 5,
                                    //             },
                                    //         }}
                                    //     >
                                    <div className="loading-carousel">
                                            {/* <div className={`scrollmenu ${this.state.subscriptionList.length<3?'d-flex justify-content-center':''}`}> */}

                                                {loadCarousel(subscriptionList)} 
                                            {/* </div> */}
                                            </div>
                                        // </Swiper>
                                        :''
                                    }


                                    {/* </div> */}
                                </div>
                                {/* </div> */}
                             {/* {subscriptionList?
                                <div className={`scrollmenu ${this.state.subscriptionList.length<3?'d-flex justify-content-center':''}`}>
                                {this.state.subscriptionList.map((item, index) => {
                                    let {featuresList}=item.features
                                    return <>
                                            <div className="plan">
                                               { item.popular>1&&<div className="special-bar blue">
                                                    Popular plan
                                                </div>}
                                                
                                                <div className={`box card ${item.popular>1?'blue':''}`}>
                                                    <p className="title text-center">{item.planName}</p>
                                                    <p className="title amount text-center"> 
                                                    {this.state.activeTab=="Monthly"?
                                                        <span>{item.planPrices?item.planPrices.planPrices[0].amount:''}{item.planPrices?item.planPrices.planPrices[0].currency=='usd'?'$':'':''}/Mth</span>
                                                        :
                                                        <span>{item.planPrices?item.planPrices.planPrices[0].amount:''}{item.planPrices?item.planPrices.planPrices[0].currency=='usd'?'$':'':''}/Yr</span>
                                                    }
                                                    </p>
                                                    <div className="textDesc">
                                                        <p className="black ">{item.description}</p>
                                                    </div>
                                                    <p className=" black sub-title">
                                                        {item.userRange?item.userRange.min:''}-{item.userRange?item.userRange.max:''} USERS + UNLIMITED CLIENTS
                                                    </p>
                                                    {item.features?
                                                    <ul className="feature-list">
                                                    {featuresList.map((item, index) => {
                                                        return <>
                                                                    <li> 
                                                                        <span className="material-icons green">
                                                                            {item.status?'done':'close'}
                                                                        </span>
                                                                        {item.value}
                                                                    </li>
                                                                </>
                                                        })
                                                    }
                                                    </ul>:''}
                                                    <Link to={`/subscription/${item.id}`}>
                                                        <NormalButton
                                                        rightIcon="arrow_forward"
                                                        className="btn-blue"
                                                        label="Select Plan"
                                                    />
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                                :''} */}
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        )
    }
}
