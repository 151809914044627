import React, {useEffect, useRef, useState} from 'react'
import './style.scss'
import { NormalSwitch } from 'components/common/NormalSwitch'
import UnitedStates from 'assets/svg/unitedStates.svg'
import India from 'assets/svg/India.svg'
import World from 'assets/svg/rest_world.svg'
import downArrow from "assets/svg/arrow-down.svg"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {getBillingPlans} from 'action/subscriptionAction'
import { getAllPricingPlan } from 'action/StripeSubscriptionAction'
import ConfirmationBox from 'components/common/ConfirmationBox'
import AgencyPlanBox from './AgencyPlanBox'
import ClientPlanBox from './ClientPlanBox'

const countries = [
    {name: 'United States',id:UnitedStates},
    {name: 'India',id:India},
    // {name: 'Rest of the world',id:World},
]
const PlanPricing = React.memo(({getBillingPlans, getAllPricingPlan}) => {
    const [plans, setPlans] = useState([])
    const [view, setView] = useState(0)
    const [country, setCountry] = useState({})
    const [countryList, setCountryList] = useState(false)
    const [planInterval, setPlanInterval] = useState(false)

    const [confirm, setConfirm] = useState({status:false, message:'', data:null})
    
    useEffect(()=>{
      if(planInterval){
        loadBillingPlans({type:2})
      }else{
        loadBillingPlans({type:1})
      }
      // loadBillingPlans()
    },[planInterval])

    const loadBillingPlans = async(query) =>{
    await getBillingPlans(query)
    .then((data)=>{
     setPlans([...data?.subscriptionMasterList])
    })
    // await getAllPricingPlan()
    // .then((data)=>{
    //   console.log(data)
    //   setPlans([...data])
    // })
    }
    let sortPlans = plans.slice().sort((a, b) => {
      if(a.displayOrder){
        return a.displayOrder - b.displayOrder
      }
     })

    const dropdownRef = useRef(null);
    const handleClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCountryList(false)
      }
    };
  
    useEffect(() => {
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);

   useEffect(()=>{
       if(process.env.REACT_APP_BASE_SERVER === '1'){
        setCountry({name: 'United States',id:UnitedStates})
       }else if(process.env.REACT_APP_BASE_SERVER === '2'){
        setCountry({name: 'India',id:India})
       }
   },[])

   const handleChangeCountry = (data) => {
        if(country.name !== data.name)
        setConfirm({status:true, message:`Are You sure to change ${data.name}`, data:data})
   }
   const handleOk = () =>{
    if(process.env.REACT_APP_HOSTMODE === 'DEV'){
      setCountry(confirm.data)
      setConfirm({status:false, message:'', data:null})
    }else{
      if(window.location.host === 'staffatom.com' && confirm.data.name ==='India'){
        window.location.href = 'https://in.staffatom.com/overview#planPricing'
      }else if(window.location.host === 'in.staffatom.com' && confirm.data.name ==='United States'){
        window.location.href = 'https://staffatom.com/overview#planPricing'
      }
    }

   }
   const handleCancel = () =>{
    setConfirm({status:false, message:'', data:null})
   }
  return (<>
  {confirm.status ? <ConfirmationBox message={confirm.message} handleOk={handleOk} handleCancel={handleCancel} /> : null}
    <div id='planPricing' className='pt-3'>
          <div className='d-flex justify-content-center align-items-center plan-main-title'>
                   Plan Pricing
          </div>
          <div className='d-flex justify-content-center align-items-center'>
                  <div className='type-toggle'>
                         <div className={"type-buttons"+ ` ${view === 0 ? 'active-button' : ''}`} onClick={()=> setView(0)}> Providing Staffing Services </div>
                         <div className={"type-buttons"+ ` ${view === 1 ? 'active-button' : ''}`} onClick={()=> setView(1)}> Looking For Staffing Services </div>
                  </div>
          </div>
           <div className='d-flex justify-content-center align-items-center' >
                  <div className='selection-row'>
                  <div className='select-country cursor-pointer 'onClick={()=> setCountryList(!countryList)} ref={dropdownRef}>
                    <div className='d-flex w-100 justify-content-between'> <label className='m-0 country-name' > {country.name} </label> <img  src={country.id} alt={country.name} style={{width:'20px'}}/> </div> <img  src={downArrow} alt='change-region' style={{width:'20px', transform: countryList ?  'rotate(-180deg)' : ''}}/>
                      {countryList ? <div className='countries-list'>
                           {countries.map((x, i)=>{
                            return(
                                <div key={i} className='d-flex justify-content-between alig-items-center' onClick={()=> handleChangeCountry(x)}>
                                    <label className='m-0 cursor-pointer' > {x.name} </label> <img  src={x.id} alt='country-flag' style={{width:'20px'}}/>
                                </div>
                            )
                           })}
                         </div> : null}
                     </div>
                  <div className='d-flex justify-content-end align-items-center multiple-plans' style={{gap:'10px'}}>
                      <label className={'m-0 '+`${!planInterval ? 'toggle-bold' : ''}`}>Monthly Pricing</label>
                      <NormalSwitch checked={planInterval} onChange={(e)=> setPlanInterval(e.target.value)} />
                      <label className={'m-0 '+`${planInterval ? 'toggle-bold' : ''}`}>Yearly Pricing</label> 
                      {/* / <label className='m-0'> Save 17%</label> */}
                  </div>
                  </div>
               
           </div>
           <div>
           {view === 0 ? (
            <div className='mt-5 d-flex justify-content-center align-items-center flex-wrap' style={{gap:'20px'}}>
                {sortPlans && sortPlans.length > 0 ? sortPlans.filter((x)=> x.subscriberType === 3 &&  x.region === country.name).map((plan,i)=>{
                  return(
                    plan.visible &&
                <AgencyPlanBox
                 plan={plan} 
                 view={view}
                 planInterval={planInterval}
                />
                  )
                }): null}
                 
            
            </div> ) :
            
            view === 1 ? (
              <div className='mt-5 d-flex justify-content-center align-items-center flex-wrap' style={{gap:'20px'}}>
                  {sortPlans && sortPlans.length > 0 ? sortPlans.filter((x)=> x.subscriberType === 7 && x.region.includes(country.name)).map((plan,i)=>{

                    return(
                      plan.visible &&
                  <ClientPlanBox
                   index={i}
                   plan={plan} 
                   view={view}
                   planInterval={planInterval}
                  />
                    )
                  }): null}
                   
              
              </div> )
            
            
            : <div style={{height:'520px'}}></div>}
           
           </div>
           <div className='d-flex justify-content-center align-items-center'>
              <span className='plan-pricing-tax-label'>* Prices are exclusive of Taxes</span>
           </div>
    </div>
    </>)
})
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getBillingPlans,
        getAllPricingPlan,
      },
      dispatch
    );
  };

export default connect(null, mapDispatchToProps)(PlanPricing);