import React, { useState } from "react";
import "./style.scss"
import userIcon from '../../assets/svg/client_user_icon.svg'
import atom_green from "../../assets/svg/atom_green.svg"
import atom_white from "../../assets/svg/atom_white.svg"
import feedback_white from "../../assets/svg/feedback-transparent.svg"
import feedback_solid from "../../assets/svg/feedback_solid.svg"
import agencySettingsSolid from "../../assets/svg/agencySettingsSolid.svg"
import agencySettingsTransparent from "../../assets/svg/agencySettingsTransparent.svg"
import subscription_solid from "../../assets/svg/Subscription-solid-filled.svg"
import subscription_white from "../../assets/svg/Subscription-white.svg"
import settings_solid from "../../assets/svg/settings_solid_green.svg"
import settings_white from "../../assets/svg/settings-transparent.svg"
import home_solid_green from "../../assets/svg/home_solid_green.svg"
import home_white from "../../assets/svg/home_white.svg"
import dashboardSolid from "../../assets/svg/dashboard.svg"
import dashboardTransparent from "../../assets/svg/dashboard_transparent.svg"

const ClientModulesSpinner = ({ selectedModule, onModuleSelect }) => {
    const agencyModulesData = [
        {
            "moduleIndex": 2,
            "imageLight": atom_white,
            "imageDark": atom_green
        },
        {
            "moduleIndex": 4,
            "imageLight": feedback_white,
            "imageDark": feedback_solid
        }, {
            "moduleIndex": 6,
            "imageLight": agencySettingsTransparent,
            "imageDark": agencySettingsSolid
        }, {
            "moduleIndex": 8,
            "imageLight": subscription_white,
            "imageDark": subscription_solid
        }, {
            "moduleIndex": 10,
            "imageLight": settings_white,
            "imageDark": settings_solid
        }, {
            "moduleIndex": 12,
            "imageLight": dashboardTransparent,
            "imageDark": dashboardSolid
        }
    ]

    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <div className="client-module-container">
            <div className="client-module-spinner">
                <div className="client-circle-wrapper">
                    <div className="client-circle">
                        <div className="client-parent">
                            <div className="client">Client</div>
                            <div className="client-streamline-staffing-effortless">Seamless staffing. Signal needs, meet requirements.</div>
                            {/* <div className="frame-parent">
                            <div className="client-subscribers-wrapper">
                                <div className="subscribers">Subscribers</div>
                            </div>
                            <div className="frame-group">
                                <img className="frame-child" alt="" src={userIcon} />
                                <div className="subscribers">1,61,5416</div>
                            </div>
                        </div> */}
                        </div>
                    </div>

                </div>
                <div className="image-holder">
                    {agencyModulesData.map((item, index) => {
                        

                        return (<div
                                 className="hexagon"
                                 key={index} 
                                 onClick={() => onModuleSelect(item.moduleIndex)} 
                                 style={{ backgroundColor: item.moduleIndex === selectedModule ? '#033c28' : '#ffffff' }}
                                 onMouseOver={() => setShowTooltip(true)}
                                 onMouseLeave={() => setShowTooltip(false)}>

                            <img src={item.moduleIndex === selectedModule ? item.imageLight : item.imageDark} alt="" />
                            {/* <div className='client-module-tooltip'>
                                <h6>Report Management For Agency</h6><br /> <br />
                                <p>
                                Our Report Management Page is your central hub for effortless report handling. Streamline your workflow as you access, organize, and analyze vital data with ease.<br /><br />
                                "Effortlessly manage and analyze your reports in one place. Make informed decisions with ease."
                                </p>
                            </div> */}

                        </div>
                        )
                    })}

                </div>
            </div>

        </div>
    )
}

export default React.memo(ClientModulesSpinner);