import React from "react";
import "./style.scss";
export class Stepper extends React.Component {
  render() {
    let { steps, activeStep } = this.props;
    return (
      <div className="row stepper-custom mb-3">
        <div className="col-12">
          <ul className="create-progress-bar">
            {steps.map(({ title,icon }, index) => {
              return (
                <li key={index} className={`${activeStep === index ? 'active' : ''} ${index  < activeStep ? 'complited' : ''}`}>
                  <span className="step-inner-wrapper">
                    <img src={icon} alt="icon"/>
                    {/* <span className="step-title"></span> */}
                    <label className="sub-text">{title}</label>
                  </span>
                 
                </li>
              )
            })}

          </ul>

        </div>
      </div>
    );
  }
}