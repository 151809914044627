import React from 'react';
import dashboardImg from '../../assets/svg/dashboardThumb.png'
import { Link } from 'react-router-dom';
import atom from '../../assets/svg/atom (1) 1.svg'
import atom1 from '../../assets/svg/atom 1.svg'
import periodicTable from '../../assets/svg/periodic-table 1.svg'
import magnifyingGlass from '../../assets/svg/magnifying-glass.svg'
import stack from '../../assets/svg/stack-of-square-papers 1.svg'
import microscope from '../../assets/svg/microscope.svg'
import arrow from '../../assets/svg/arrow_forward.svg'
import bgOne from '../../assets/svg/bg one.svg'
import "./style.scss"
import Product from 'components/ProductDetails';

export default function AboutUs({handleDialogBox=()=>{},}) {
    return (
        <>

            <div id="aboutus" className="about-content">
                <div className="position-relative about-body">
                <div className="responsive-header"> <p>Agency</p> <p>Client</p></div>
                    <div className="about-container d-flex justify-content-center">
                        {/* <img className="about-img" src={dashboardImg} alt="about" /> */}
                        <Product />
                    </div>
                    {/* <div className='curved'>
                        <img src={bgOne} alt="bgOne"></img>
                    </div> */}
                </div>
                <div className="row box-bg-dark about-box-layout m-0">
                    <div className="col box box-dark">
                        <img src={atom} alt="atom" />
                        <h6 className="title">
                            On Demand Scheduling
                        </h6>
                    </div>
                    <div className="col box box-grey">
                        <img src={periodicTable} alt="periodic table" />
                        <h6 className="title">
                            Client &amp; Staff
                            Profile Management
                        </h6>
                    </div>
                    <div className="col-6 align-self-center text-center px-5 staff">
                        <h3 className="text-white">What is Staffatom ?</h3>
                        <p>
                            Staffatom is workforce management software that better serves the needs of staffing agencies because it was designed by staffing agency professionals inputting real world "IQ" on what agencies face with staff and clients.
                        </p>
                        <div className="d-flex justify-content-center my-4">
                                <button onClick={handleDialogBox} className="btn-rounded-circle d-flex align-items-center justify-content-between">Free 30-Days Trial<img src={arrow} alt="arrow" /></button>
                        </div>
                    </div>
                </div>
                <div className="row bg-black about-box-layout m-0">
                    <div className="col box box-atom-bg">
                        <img src={magnifyingGlass} alt="magnifying glass" />
                        <h6 className="title">
                            Recruitment Matching Tool
                        </h6>
                    </div>
                    <div className="col box box-grey box-atom-pink">
                        <img src={atom1} alt="atom1" />
                        <h6 className="title"> Portal for tasks &amp; communication  </h6>
                    </div>
                    <div className="col box box-grey">
                        <img src={stack} alt="stack" />
                        <h6 className="title"> Invoicing &amp; Billing Support  </h6>
                    </div><div className="col box bg-black">
                        <img src={microscope} alt="microscope" />
                        <h6 className="title"> Reporting &amp; Audit Logs  </h6>
                    </div>
                </div>
                <div className="row small-mob justify-content-center">
                    <div className="col-12 align-self-center text-center px-5">
                        <h3 className="text-white">What is Staffatom ?</h3>
                        <p>
                            Staffatom is workforce management software that better serves the needs of staffing agencies because it was designed by staffing agency professionals inputting real world "IQ" on what agencies face with staff and clients.
                        </p>
                    </div>
                </div>
                <div className="row bg-black justify-content-center small-mob-box">
                    <div className="col-3 box box-grey">
                        <img src={atom} alt="atom" />
                        <h6 className="title">
                            On Demand Scheduling
                        </h6>
                    </div>
                    <div className="col-3 box box-grey">
                        <img src={periodicTable} alt="periodic table" />
                        <h6 className="title">
                            Client &amp; Staff <br />
                            Profile <br /> Management
                        </h6>
                    </div>
                    <div className="col-3 box box-grey">
                        <img src={magnifyingGlass} alt="magnifyingGlass" />
                        <h6 className="title">
                            Recruitment <br /> Matching Tool
                        </h6>
                    </div>
                    <div className="col-3 box box-grey ">
                        <img src={stack} alt="stack" />
                        <h6 className="title">
                            Invoicing &amp; <br /> Billing Support
                        </h6>
                    </div>
                    <div className="col-3 box box-grey">
                        <img src={atom1} alt="atom1" />
                        <h6 className="title">
                            Portal for tasks &amp; <br /> communication
                        </h6>
                    </div>
                    <div className="col-3 box bg-black">
                        <img src={microscope} alt="microscope" />
                        <h6 className="title"> Reporting &amp;<br></br>Audit Logs  </h6>
                    </div>
                    {/* <div className="col-4 box bg-black">
                            <img src={microscope}/>
                            <h6 className="title">
                                Client &amp; Staff
                                Profile Management
                            </h6>
                        </div> */}
                    <div className="col-12 d-flex justify-content-center my-4">
                        {/* <Link smooth="true" to={`/freeAccount`} > */}
                            <button className="btn-rounded-circle d-flex align-items-center justify-content-center" onClick={handleDialogBox}>Free 30-days Trial<img className="ml-2" src={arrow} alt="arrow" /></button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>



        </>
    );
}