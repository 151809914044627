import React, { useState } from "react";
import "./style.scss";
import checkboxIcon from "assets/svg/client-checkbox-icon.svg";
import winningMedalIcon from "assets/svg/client-winning-medal-award.svg";
import PlusPlanIcon from "assets/svg/client-plan-plus-medal.svg";
import BgWave1 from "assets/svg/wave-bg-1.svg";
import BgWave2 from "assets/svg/wave-bg-2.svg";
import BgWave3 from "assets/svg/wave-bg-3.svg";
import biStars from "assets/svg/bi_stars.svg";
import {useHistory} from "react-router-dom";
import { standardClientPlanFeatures } from "service/utilities";

const bg = [BgWave1, BgWave2, BgWave3];
const planTypes = {
  0: "Regular",
  1: "Popular",
  2: "Recommended",
};

const ClientPlanBox = (props) => {
  let { index = null, plan, view, planInterval } = props;
  let { baseAmount, symbol, billingType } = plan;

const [isSelected, setIsSelected] = useState(false)
  const history = useHistory();
  const handleNavigate = () =>{
    setIsSelected(true)
    setTimeout(()=>{
      history.push(`/subscription/${plan?.id}/${planInterval ? "Yearly" : "Monthly"}/${
        view === 0 ? "provider" : "seeker"
      }`)
    },300)
  }
  
  return (
      <div className={`client-subscription-plan-box-container`} onClick={handleNavigate} >
        <img
          className="client-gradient-bg"
          src={bg[index === null ? 1 : index > 2 ? index % 3 : index]}
          alt="subsciption"
        />

        <div key={plan.id} className={`client-plan-price-box`}>
          <div className="client-plan-price-details"  style={{background:isSelected?'#003521':''}}>
            <div className="front-view-main-labels">
              <div className="client-plan-category-label">
                <img
                  src={plan.planType == 0 ? winningMedalIcon : PlusPlanIcon}
                  alt="plan-category"
                  style={{ width: "32px" }}
                />{" "}
                {planTypes[plan?.planType]}
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center">
              <span className="client-back-view-plan-price-span2">
                {" "}
                {symbol}{" "}
              </span>
              <span className="client-back-view-plan-price-span">
                {baseAmount}
              </span>
              <span className="client-back-view-plan-price-span3">
                /
                {billingType == 1
                  ? "Monthly"
                  : billingType == 2
                  ? "Yearly"
                  : null}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center align-item-center"></div>
          <div className="client-grey-label">
            {plan.baseUserLimit} AGENCY CONNECTS
          </div>
          <div className="client-plan-name"  style={{background:isSelected?'#003521':''}}>
          {plan.planName.length < 45
              ? plan.planName
              : `${plan.planName.slice(0, 44)} . . .`}
          </div>
          {standardClientPlanFeatures.map((item) => {
            return (
              <div className="d-flex justify-content-start pl-4 mb-1">
                <img
                  src={checkboxIcon}
                  className="mr-2"
                  alt=""
                  style={{ width: "20px" }}
                />
                <span className="client-feature-name">{item}</span>
              </div>
            );
          })}
          {plan.features &&
            plan.features.map((item) => {
              return (
                <div className="d-flex justify-content-start pl-4 mb-2">
                  <img
                    src={checkboxIcon}
                    className="mr-2"
                    alt=""
                    style={{ width: "20px" }}
                  />
                  <span className="client-feature-name">{item.value}</span>
                </div>
              );
            })}
          {plan.pricingModel === 2 ? (
            <div className="dynamic-seat client-dynamic-seat">
              <img
                src={biStars}
                width={20}
                className="mr-2"
                alt=""
                style={{ width: "16px" }}
              />
              Dynamic Seat Licensing
            </div>
          ) : null}
        </div>
      </div>
  );
};

export default React.memo(ClientPlanBox);
