import React, { Component,Suspense } from 'react';
import { Route, Router } from 'react-router-dom';
import routers from './routes';
import * as Layout from '../layout';
import * as Pages from '../pages';
import { history } from '../helpers';
import { NotificationContainer } from 'react-notifications';
import { CommonPageLoader } from 'components/common/typography/CommonPageLoader';

class Routes extends Component {
	render() {
		return (
			<Router history={history}>
				<Suspense fallback={<div><CommonPageLoader /></div>}>
				{routers.map(({ component, redirect, path, exact = false, // auth = true,
					childrens = [] }) => {
					if (childrens.length > 0) {
						return (
							<Route
								path={path}
								exact={exact}
								key={path}
								onEnter={this.requireAuth}
								render={(props) => {
									if (redirect) {
										if (props.location.pathname === path) {
											props.history.push(redirect);
										}
									}

									const LayoutComponent = Layout[component];

									return (
										<LayoutComponent {...props}>
											<Suspense fallback={<div><CommonPageLoader /></div>}>
											{childrens.map(
												({
													component: ChildrenComponent,
													path: childrenPath,
													exact = false,
													// auth = true
												}) => {
													return (
														<Route
															path={path + childrenPath}
															exact={exact}
															key={path + childrenPath}
															onEnter={this.requireAuth}
															render={(props) => {
																let PageComponent = Pages[ChildrenComponent];

																return <PageComponent {...props} />;
															}}
														/>
													);
												},
											)}
											</Suspense>
										</LayoutComponent>
									);
								}}
							/>
						);
					} else {
						return (
							<Route
								path={path}
								exact={exact}
								key={path}
								onEnter={this.requireAuth}
								render={(props) => {
									if (redirect) {
										if (props.location.pathname === path) {
											props.history.push(redirect);
										}
									} else {
										let PageComponent = Pages[component];

										return <PageComponent {...props} />;
									}
								}}
							/>
						);
					}
				})}
				<NotificationContainer />
				</Suspense>
			</Router>
		);
	}
}

export default Routes;
