import React from "react";
import Swiper from 'react-id-swiper';
import './testimonials.scss';
import icon1 from '../../../../assets/images/icons/icon1.svg';
export class Testimonials extends React.Component {

    state = {
        params: {
            loop: false,
            spaceBetween: 30,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: false,
                type: 'bullets'
            },
            containerClass: 'swiper-container different'
        }
    }


    render() {
        let { params, } = this.state
        let companies = [
           "I was spending on three different SaaS apps to run my nurse staffing agency and to still found them not intuitive and still requiring a lot of tedious back and forth. Staff Atom was created to eliminate all that."
        ]
        // Load single page for Home
        const loadSingle = (item, index) => {
            // console.log(item)
            return (
                <div className="corousel-outline" key={index}>
                    <div className="inside">

                        <div className="position">
                            <img src={icon1} alt="icons" />
                        </div>
                        <p>{item}</p>
                        <div className="footer">
                            <p>Tanvi Bharathan</p>
                            <span>
                                Founder - Willow Staffing Agency , USA
                            </span>
                        </div>
                    </div>
                </div>
            )
        }



        // Load carousel for Additional Home
        const loadCarousel = (data) => {

            // data.map((item, index) => console.log(item))
            return (
                data.map((item, index) => loadSingle(item, index))
            )
        }

        return (
            <section id="testimonials">
                <div className="container-fluid bg">
                    <div className="row">
                        <div className="col">
                            <div className="clients">
                                <div className="swiper">
                                    {/* <div className="card"> */}
                                    <Swiper {...params}
                                        shouldSwiperUpdate
                                    >

                                        {loadCarousel(companies)}
                                    </Swiper>


                                    {/* </div> */}

                                </div>
                                <div className="circle"></div>
                                <div className="circle right"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
