import React from 'react';
import "./NormalInput.scss";
import infoIcon from "../../../../assets/svg/info.svg";


export const NormalInput = ({
    className = "form-control",
    placeholder = "",
    label = "",
    onChange,
    onKeyPress,
    value = "",
    name,
    disabled = false,
    type = 'text',
    isLink = false,
    info=false
}) => {
    return (
        <div className="normal-input">
            {label !== '' ?
                <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">
                    {label} {info?<img className="ml-4" src={infoIcon} alt="infoIcon"/>:''}
                </div>
                : ''}
            <input
                className={`${isLink ? `${className} islink` : className} no-default-show-hide-icon`}
                name={name}
                type={type}
                disabled={disabled}
                value={value}
                min={0}
                placeholder={placeholder}
                onKeyPress={e => {
                    onKeyPress(e)
                }}
                onChange={e => {

                    let body = {}

                    body = {
                        target: {
                            name: e.target.name,
                            value: e.target.value
                        }
                    }

                    onChange(body)

                }}
            />
            {isLink
                ?
                <span className="link-area">
                    <i className="fs-24 icon-link"></i>
                </span>
                : ''}
        </div>
    )
}