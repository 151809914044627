import React from "react";
import './remoteWorking.scss';
import Swiper from 'react-id-swiper'; 
import remote from "../../../../assets/svg/mobile-remote.png";

export class RemoteWorking extends React.Component {
    state = {
        params: {
            loop: true,
            spaceBetween: 30,
            // autoplay: {
            //     delay: 3000,
            //     disableOnInteraction: false
            // },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: false,
                type: 'bullets'
            },
            containerClass: 'swiper-container different'
        }
    }
    render() {
        let { params, } = this.state
        let companies = [
            {
                img: "/img/screen1.png", title: `Balance onsite workforce load distribution smartly`
            },
            {
                img: "/img/screen2.png", title: `Accelerate business with "Automated-Intelli-Invoicing" solution`
            },
            {
                img: "/img/screen3.png", title: `Handle all your work schedule requests at ease`
            },
            {
                img: "/img/screen4.png", title: `Onboard and manage staff profiles without HRMS`
            },
        ]
        // Load single page for Home

        const loadSingle = (item, index) => {
            return (
                <div className="corousel-outline" key={index}>
                    <img src={item.img} alt="imageSource" />
                    <div className="content">
                        <h2>
                            {item.title}
                        </h2>
                        <p>{item.para}</p>
                    </div>
                </div>
            )
        }
        // Load carousel for Additional Home
        const loadCarousel = (data) => {

            // data.map((item, index) => console.log(item))
            return (
                data.map((item, index) => loadSingle(item, index))
            )
        }
        return (
            <>

                <section id="remoteWorking">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading">
                                    <h1>Make Life Easier By Automating Common Tedious Tasks</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bar"></div>
                    <div className="container-fluid position">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="outline">
                                            <div className="align-midle">
                                                <h2>Balance onsite workforce load distribution smartly</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="outline-image">
                                            <div className="image-outline">
                                                <img src="/img/screen1.png" alt="imageSource" className="image-source" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-decorate">
                            <img src="/img/Rectangle2286.png" alt="imageSource" />
                        </div>
                    </div>
                    <div className="bar"></div>
                    <div className="container-fluid position">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="outline-image">
                                            <div className="image-outline">
                                                <img src="/img/screen2.png" alt="imageSource" className="image-source" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="outline">
                                            <div className="align-midle left">
                                                <h2>Accelerate business with "Automated-Intelli-Invoicing" solution</h2>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="bg-decorate left">
                            <img src="/img/Rectangle2287.png" alt="imageSource" />
                        </div>
                    </div>
                    <div className="bar"></div>
                    <div className="container-fluid position">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="outline">
                                            <div className="align-midle">
                                                <h2>Handle all your work schedule requests at ease</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="outline-image">
                                            <div className="image-outline">
                                                <img src="/img/screen3.png" alt="imageSource" className="image-source" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-decorate">
                            <img src="/img/Rectangle2288.png" alt="imageSource" />
                        </div>
                    </div>
                    <div className="bar"></div>
                    <div className="container-fluid position">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="outline-image">
                                            <div className="image-outline">
                                                <img src="/img/screen4.png" alt="imageSource" className="image-source" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="outline">
                                            <div className="align-midle left">
                                                <h2>Onboard and manage staff profiles without HRMS</h2>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="bg-decorate left">
                            <img src="/img/Rectangle2289.png" alt="imageSource" />
                        </div>
                    </div>
                </section>
                <section className="remote-mobile" id="remote-Working">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="clients">
                                    <h1>Make Life Easier By Automating Common Tedious Tasks</h1>
                                    <div className="swiper">
                                        <Swiper {...params}
                                            shouldSwiperUpdate
                                        >
                                        {loadCarousel(companies)}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-decorate mobile">
                        <img src={remote} alt="imageSource" />
                    </div>
                </section>
            </>
        )
    }
}
