import React from 'react'
import './style.scss'
const SkeletonLoader = () => {

    const getSkeletonLoader = (noOfModules) => {
        const modules = Array.from({ length: noOfModules }, (item, index) => {
            return index;
        });
        return modules.map((item) => {
            return <div className='skeleton module-skeleton-container'></div>
        })
    }

    return (
        <>
            <div className="agency-skeleton-container">
                <div className="agency-skeleton-spinner">
                    <div className="skeleton-circle-wrapper">
                        <div className="skeleton-circle">
                            <div className="agency-skeleton-parent">
                                <div className="skeleton spinner-header-text"></div>
                                <div className="skeleton placeholder-text"></div>
                                <div className="skeleton placeholder-text"></div>
                                <div className="skeleton placeholder-text"></div>
                            </div>
                        </div>

                    </div>
                    <div className="image-holder">
                        {getSkeletonLoader(10)}

                    </div>
                </div>

            </div>

            <div className='card-skeleton'>
                <div className='skeleton card-top'>

                </div>
                <div>

                </div>
            </div>
            <div className="client-skeleton-container">
                <div className="client-skeleton-spinner">
                    <div className="skeleton-circle-wrapper">
                        <div className="client-skeleton-circle">
                            <div className="client-skeleton-parent">
                                <div className="skeleton spinner-header-text client-text"></div>
                                <div className="skeleton placeholder-text"></div>
                                <div className="skeleton placeholder-text"></div>
                                <div className="skeleton placeholder-text"></div>

                            </div>
                        </div>

                    </div>
                    <div className="client-module-holder">
                        {getSkeletonLoader(6)}

                    </div>
                </div>

            </div>
        </>
    )
}

export default SkeletonLoader;