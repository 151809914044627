import React, { useState } from "react";
import AgencyModulesSpinner from "components/AgencyModulesSpinner";
import ClientModulesSpinner from "components/ClientModulesSpinner";
import './style.scss'
import ProductDetailCard from "components/ProductDetailCard";
import SkeletonLoader from "components/SkeletonLoader";

const Product = () => {

    const [selectedModule, setSelectedModule] = useState()
    const [isLoaded, setIsLoaded] = useState(false)

    const onModuleSelect = (index) => {
        if(index === 17) {
            setSelectedModule(1)
        }
        else {
            setSelectedModule(index)
        }
    }

    return (
        <>

            <div
                className="product-details-container"
                style={{ display: isLoaded ? 'grid' : 'none' }}
            >
                <AgencyModulesSpinner selectedModule={selectedModule} onModuleSelect={onModuleSelect} />
                <ProductDetailCard selectedModule={selectedModule} onModuleSelect={onModuleSelect} setIsLoaded={setIsLoaded} />
                <ClientModulesSpinner selectedModule={selectedModule} onModuleSelect={onModuleSelect} />
            </div>
            <div className="skeleton-loader"
            style={{ display: isLoaded ? 'none' : '' }}
            >
                <SkeletonLoader />
            </div>

        </>

    )
}

export default React.memo(Product);