import React, { Component } from 'react';
import '../../assets/scss/layouts/Auth.scss';
import logo from '../../assets/images/Staffatom.png'
import { Link } from 'react-router-dom';
// import { history } from '../../service/helpers';
// import bg from '../../assets/images/bg.png'
import LadyLogo from '../../assets/images/LadyLogo.png'
// import arrowback from 'assets/svg/arrow_back.svg';


export class AuthLayout extends Component {
  state = {
   
  };

  
 
   render() {

  //   const goToPreviousPath = () => {
  //     history.goBack()
  // }
}
 

  render() {
  //   const goToPreviousPath = () => {
  //     history.goBack()
  // }
    return (
      <>
        <div className='wrapper'>
          <div className='banner'>
            <div>
            <div className="text-item">
              <Link to={'/overview#planPricing'}>
                <div className=''>
                <img src={logo} alt="Logo" className='logo' />
                <h5 className="text">Manage On-Site WorkForce Smartly...</h5>
                </div>
              </Link>
            </div>
              <img className='lady-logo' src={LadyLogo}/>
            </div>
          </div>

          <div className='content-auth'>
            <div className=" subscription">
              {this.props.children}
            </div>
          </div>
        </div>
        <style jsx="true">
          {`
            html {
              overflow-x: hidden;
            }
          `}
        </style>
      </>
    );
  }
}
