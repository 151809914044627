import React, { useState,useRef, useEffect } from 'react';
import callIcon from '../../assets/svg/call-icon.svg';
import locationIcon from '../../assets/svg/location-icon.svg';
import mailIcon from '../../assets/svg/mail.svg';
import './style.scss';
import { NormalButton, NormalSelect } from '../common';
import { api } from '../../service/api';
import { Toast } from '../../service/toast';
import SimpleReactValidator from "simple-react-validator";
import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
const categoryOptions = [
	{ label: 'Testing', value: 'testing' },
	{ label: 'Medium', value: 'Medium' },
	{ label: 'Hard', value: 'Hard' },
];
export default function ContactUsForm() {
	// inputs
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [category, setCategory] = useState('');
	const [question, setQuestion] = useState('');
	const [loading,setLoading]=useState(false);
	
	const [submit, setSubmit] = useState(false);

	const phoneUtil = PhoneNumberUtil.getInstance()
    const isPhoneValid = (phone) => {
      try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
      } catch (error) {
        return false;
      }
    };

	const validator = useRef(
		new SimpleReactValidator({
		  validators: {
			email: {
			  message: "must be a valid Email.",
			  rule: (val, params, validator) => {
				return (
				  validator.helpers.testRegex(val, /^[0-9]{10}$/) ||
				  validator.helpers.testRegex(
					val,
					/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/
				  )
				);
			  },
			},
			  phone: {
				message: "must be a valid Phone number.",
				rule: (val, params, validator) => {
				  return (
					validator.helpers.testRegex(val, /^[0-9]{10}$/) 
				  );
				},
			  messageReplace: (message, params) =>
				message.replace("", this.helpers.toSentence(params)),
			  required: true,
			},
			element: (message) => (
			  <span className="error-message  font-md">{message}</span>
			),
		  },
		})
	  );
	  const formValid = validator.current.allValid();

	  useEffect(() => {
		if(!formValid&&submit){
			validator.current.showMessages();
		}
		else{
			validator.current.hideMessages();
		}
		}, [formValid,submit]);

	// Submit form
	function submitContactForm() {
		if(mobileNumber && !isPhoneValid(mobileNumber)){
			Toast({type: 'error', message:'Invalid number', time: 3000})
			return;
		}
		setSubmit(true)
		if(formValid){
		setSubmit(false)
		setLoading(true)
		let body = { name, email, companyName, mobileNumber, category, question };
		body.email = body.email.toLowerCase();
		let result = new Promise((resolve, reject) => {
			api({
				method: 'post',
				api: 'contactUs/contact',
				status: false,
				body: JSON.stringify(body),
				baseURL: 'contact',
			})
				.then(({ data,status,message }) => {
					resolve(data);
					setLoading(false)
					if(status){
						Toast({ type: 'success', message: 'Successfully submitted!' });
						setName('');
						setEmail('');
						setCompanyName('');
						setMobileNumber('');
						setCategory('');//once categories list received, then it can be uncommented.
						setQuestion('');
					}
				})
				.catch(({ message, status }) => {
					setLoading(false)
					if (status) {
						Toast({ type: 'success', message: message ? message : 'Something went wrong' });
						setName('');
						setEmail('');
						setCompanyName('');
						setMobileNumber('');
						setCategory('');//once categories list received, then it can be uncommented.
						setQuestion('');
					} else {
						reject(Toast({ type: 'error', message: message ? message : 'Something went wrong' }));
					}
				});
		});
		if (result.status) {
			Toast({ type: 'success', message: 'Successfully submitted!' });
		}

		}
		else{
				validator.current.showMessages();
				setLoading(false)
		}
		
	}
	return (
		<React.Fragment>
			<section  className='contact-form'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-lg-6 col-sm-12'>
							<div className='heading'>
								<h1>Contact Information</h1>
								{/* <p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit,ut labore et dolore magna
									aliqua.
								</p> */}
								<div className='d-flex'>
									<img src={mailIcon} alt='mail' />
									<p className='m-3'>support@staffatom.com</p>
								</div>
								<div className='d-flex'>
									<img src={callIcon} alt='call' />
									<p className='m-3'>0427-764367</p>
								</div>
								<div className='d-flex'>
									<img src={locationIcon} alt='location' />
									<p className='m-3'>address</p>
								</div>
							</div>
						</div>
						<div className='col-lg-6 col-sm-12'>
							<div className='row'>
								<div className='col-md-6 col-12 form'>
									<input
										autoComplete="none"
										type='text'
										value={name}
										className='form-input'
										placeholder='Full Name'
										onChange={(e) => setName(e.target.value)}
									/>
									{validator.current.message("Name", name, "required|alpha_space")}
								</div>
								<div className='col-md-6 col-12 form'>
									<input
										autoComplete="none"
										type='text'
										value={email}
										className='form-input'
										placeholder='Email'
										onChange={(e) => setEmail(e.target.value)}
									/>
									{validator.current.message("Email", email, "required|email")}
								</div>
								<div className='col-md-6 my-4 col-12 form'>
									<input
										autoComplete="none"
										type='text'
										value={companyName}
										className='form-input companyNameField'
										placeholder='Company Name'
										onChange={(e) => setCompanyName(e.target.value)}
									/>
									{validator.current.message("Company name", companyName, "required")}
								</div>
								<div className='col-md-6 my-4 col-12 form'>
									{/* <input
										autoComplete="none"
										type='number'
										value={mobileNumber}
										className='form-input'
										placeholder='Phone'
										onChange={(e) => {
											if(e.target.value.length<11){
											setMobileNumber(e.target.value)
											}
										}}
									/> */}
									<PhoneInput
                   						className='form-input'
                    					defaultCountry={process.env.REACT_APP_BASE_SERVER === '1' ? 'us' : 'in'}
                    					placeholder="Phone"
                    					value={mobileNumber}
										onChange={(phone) => {
											setMobileNumber(phone)
										}}
										/>
									{validator.current.message("Phone number", mobileNumber, "required")}
								</div>
								<div className='col-md-12 col-12 mb-4 form pr-0'>
									<NormalSelect 
										placeholder='Select category'
										name='category'
										className='form-control rounded-0'
										value={category}
										options={categoryOptions}
										handleChange={(e) => setCategory(e.target.value)}
										
									/>
									{validator.current.message("Category", category, "required")}
								</div>
								<div className='col-md-12 col-12 mt-2 form'>
									<textarea
										autoComplete="off"
										placeholder='Ask your question'
										className='w-100 p-3'
										value={question}
										onChange={(e) => setQuestion(e.target.value)}
									/>
									{validator.current.message("Question", question, "required")}
								</div>
								<span className='enquiry py-3'>
									{' '}
									<i>No spams will be received by the visitors submitting the contact us enquiry</i>
								</span>
								<div className='d-flex justify-content-center w-100'>
									<NormalButton
										rightIcon='arrow_forward'
										className='btn-blue send'
										disabled={loading}
										label={loading?'Loading...':'Send'}
										onClick={submitContactForm}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
}
