import React, { Component } from 'react';
import '../../assets/scss/layouts/Auth.scss';
import logo from '../../assets/images/Staff IQ.png'
import { Link } from 'react-router-dom';

export class FreeAccountLayout extends Component {
   // constructor() {
   //     super();
   // }
  
   render() {
      

      return (
          <>
          <div className="row">
            <div className="col-md-5 auth-layout d-flex align-items-center justify-content-center text-center">
            <Link to={'/overview'}>
              <img src={logo} alt="Logo"  />
            </Link>
            </div>
            {/* <div className="col-md-6 d-flex align-items-center"> */}
            <div className="col-md-7 subscription">
              {/* <div className="auth-panel"> */}
            {this.props.children}
            {/* </div> */}
              
            </div>
        </div>
        
        <style jsx="true">

                        {`
                        html {
                          overflow-x: hidden;
                         }
                         
                        `}
        </style>
      </>
      );
   }
}
