import { authApi } from "../service/apiVariables";
import { addQuery } from '../service/helperFunctions';

// common login
export const login = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...authApi.adminLogin, body })
      .then(({ message, data }) => {
        resolve(data);

        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const otplogin = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...authApi.otpLogin, body })
      .then(({ message, data }) => {
        // localStorage.setItem("AuthToken", data.token);
        // localStorage.setItem("userData", JSON.stringify(data));
        resolve(data);
        if(data.userType !== 1 && data.userType !== 2) {
          Toast({ type: "success", message, time: 5000 });
        }
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const forgotPassword = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, authApi.forgetPassword);
    authApi.forgetPassword.query.emailId=encodeURIComponent(authApi.forgetPassword.query.emailId)
    api({ ...authApi.forgetPassword })
      .then(({ message, data }) => { 

        resolve(data);

        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
 

export const resetpassCode = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...authApi.resetPassword, body })
      .then(({ message, data }) => { 

        resolve(data);
        message = message?message:"";
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};


export const getUserIp = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...authApi.getIp })
      .then(({data }) => { 
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
 
