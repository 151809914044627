import React, { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import agencyWR from '../../assets/images/Agency_WS.png'
import Agency_timesheet from '../../assets/images/Agency_timesheet_review.png'
import Agency_staff from '../../assets/images/Agency_staff.png'
import Agency_clients from '../../assets/images/Agency_clients.png'
import Agency_invoices from '../../assets/images/Agency_invoices.png'
import Agency_timeoff from '../../assets/images/Agency_time off.png'
import Agency_reports from '../../assets/images/Agency_reports.png'
import Agency_audit from '../../assets/images/Agency_auditlogs.png'
import Agency_subscriptions from '../../assets/images/Agency_subscriptions.png'
import Agency_settings from '../../assets/images/Agency_settings.png'
import Client_WS from '../../assets/images/Client_WS.png'
import Client_feedback from '../../assets/images/Client_feedback.png'
import Client_agency from '../../assets/images/Client_agency_management.png'
import Client_settings from '../../assets/images/Client_settings.png'
import Client_subscriptions from '../../assets/images/Client_subscriptions.png'
import clientDashboard from '../../assets/images/client_dashboard.png'
import { useState } from "react";
import './style.scss'
import question_mark_blue from '../../assets/svg/question_mark_blue.svg'
import question_mark_green from '../../assets/svg/question_mark_green.svg'
import home_solid_blue from '../../assets/svg/home_solid_blue.svg'
import home_solid_green from '../../assets/svg/home_solid_green.svg'
import home_white from '../../assets/svg/home_white.svg'
import KeyFeatures from "components/KeyFeatures";
import Benefits from "components/Benefits";

const ProductDetailCard = ({ selectedModule, onModuleSelect, setIsLoaded }) => {

    const [productDetailsData, setProductDetailsData] = useState([
        {
            moduleIndex: 1,
            image: agencyWR,
            portal: 'agency'
        },
        {
            moduleIndex: 2,
            image: Client_WS,
            portal: 'client'
        },
        {
            moduleIndex: 3,
            image: Agency_timesheet,
            portal: 'agency'
        },
        {
            moduleIndex: 4,
            image: Client_feedback,
            portal: 'client'
        },
        {
            moduleIndex: 5,
            image: Agency_staff,
            portal: 'agency'
        },
        {
            moduleIndex: 6,
            image: Client_agency,
            portal: 'client'
        },
        {
            moduleIndex: 7,
            image: Agency_clients,
            portal: 'agency'
        },
        {
            moduleIndex: 8,
            image: Client_subscriptions,
            portal: 'client'
        },
        {
            moduleIndex: 9,
            image: Agency_invoices,
            portal: 'agency'
        },
        {
            moduleIndex: 10,
            image: Client_settings,
            portal: 'client'
        },
        {
            moduleIndex: 11,
            image: Agency_timeoff,
            portal: 'agency'
        },
        {
            moduleIndex: 12,
            image: clientDashboard,
            portal: 'client'
        },
        {
            moduleIndex: 13,
            image: Agency_reports,
            portal: 'agency'
        },
        {
            moduleIndex: 14,
            image: Agency_audit,
            portal: 'agency'
        },
        {
            moduleIndex: 15,
            image: Agency_subscriptions,
            portal: 'agency'
        },
        {
            moduleIndex: 16,
            image: Agency_settings,
            portal: 'agency'
        },
    ])

    const [selectedBtn, setSelectedBtn] = useState('home')
    const [portalName, setPortalName] = useState('agency')
    const [flip, setFlip] = useState(false)
    const [count, setCount] = useState(0)
    const [selectCount, setSelectCount] = useState(selectedModule);

    useEffect(() => {
        const filteredObj = productDetailsData.filter((item) => item.moduleIndex === selectedModule)[0]
        setPortalName(filteredObj?.portal || 'agency')
        setSelectCount(selectedModule)
    },[selectedModule])

    const handleSelect = (selectedIndex, e) => {
        onModuleSelect(selectedIndex + 1)
        // const filteredObj = productDetailsData.filter((item) => item.moduleIndex === (selectedIndex + 1))[0]
        // setPortalName(filteredObj?.portal)
    }

    const onCardBtnClick = (type) => {
        setSelectedBtn(type)
    }

    const handleLoad = () => {
        if(count <= 16) {
            setCount(prevCount => {
                const updatedCount = prevCount + 1
                if(updatedCount === 16) {
                    setTimeout(() => {
                        setIsLoaded(true)
                    },2000)
                }
                return updatedCount
            })
    
        }
        
    }

    return (
        <>
            <div className="product-detail-card">

                <Carousel
                    controls={false}
                    fade={true}
                    defaultActiveIndex={0}
                    activeIndex={(selectCount && selectCount - 1) || 0}
                    onSelect={handleSelect}
                    interval={(selectedBtn === 'home' && selectedModule !== 17) ? 3000 : (selectedBtn === 'home' && selectedModule === 17) ? 0 : null}
                >
                    {productDetailsData && productDetailsData.map((item, index) => {
                        return (

                            <Carousel.Item >

                                <div className={`card-flipper ${flip ? 'flip' : ''} ${portalName === 'agency' ? 'agency-module' : 'client-module'}`}>
                                    <div className="card-front">
                                        <img fetchPriority="high" la className='module-image' alt={item.portal} src={item.image} onLoad={handleLoad}/>
                                    </div>
                                    <div className="card-back">
                                        {selectedBtn === 'features' && <KeyFeatures featureData={item} />}
                                        {selectedBtn === 'benefits' && <Benefits featureData={item} />}
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    })}
                    {portalName === 'agency' ?
                        <div className="card-btns">
                            <button className={` ${selectedBtn === 'features' ? 'agency-key-features-btn-active' : 'agency-key-features-btn'}`} onClick={() => { onCardBtnClick('features'); setFlip(true) }}>Key Features</button>
                            <button className={`${selectedBtn === 'benefits' ? 'agency-benefits-btn-active' : 'agency-benefits-btn'}`} onClick={() => { onCardBtnClick('benefits'); setFlip(true) }}>Benefits</button>
                            <button className={`${selectedBtn === 'home' ? 'agency-home-active' : 'agency-home'}`} onClick={() => { onCardBtnClick('home'); setFlip(false) }}><img src={selectedBtn === 'home' ? home_white : home_solid_blue} alt="home" height={"20px"} weight={"20px"} /></button>
                        </div>
                        :
                        <div className="card-btns">
                            <button className={`${selectedBtn === 'features' ? 'client-key-features-btn-active' : 'client-key-features-btn'}`} onClick={() => { onCardBtnClick('features'); setFlip(true) }}>Key Features</button>
                            <button className={`${selectedBtn === 'benefits' ? 'client-benefits-btn-active' : 'client-benefits-btn'}`} onClick={() => { onCardBtnClick('benefits'); setFlip(true) }}>Benefits</button>
                            <button className={`${selectedBtn === 'home' ? 'client-home-active' : 'client-home'}`} onClick={() => { onCardBtnClick('home'); setFlip(false) }}><img src={selectedBtn === 'home' ? home_white : home_solid_green} alt="home" height={"20px"} weight={"20px"} /></button>
                        </div>
                    }
                </Carousel>
                <div className="slide-dots">
                    <ol>
                        <li className={`${selectCount <= productDetailsData.length/5 ? "active-bar":'active-animation'} ${portalName === 'agency' ? '' : 'client-slide'}`}></li>
                        <li className={`${selectCount <= (productDetailsData.length *2)/5 && selectCount > productDetailsData.length/5 ? "active-bar":'active-animation'} ${portalName === 'agency' ? '' : 'client-slide'}`}></li>
                        <li className={`${selectCount <= (productDetailsData.length *3)/5 && selectCount > (productDetailsData.length *2)/5 ? "active-bar":'active-animation'} ${portalName === 'agency' ? '' : 'client-slide'}`}></li>
                        <li className={`${selectCount <= (productDetailsData.length *4)/5 && selectCount > (productDetailsData.length *3)/5 ? "active-bar":'active-animation'} ${portalName === 'agency' ? '' : 'client-slide'}`}></li>
                        <li className={`${selectCount <= productDetailsData.length && selectCount > (productDetailsData.length *4)/5 ? "active-bar":'active-animation'} ${portalName === 'agency' ? '' : 'client-slide'}`}></li>
                    </ol>
                    <ol>
                        {productDetailsData && productDetailsData.map((data, index)=>{
                            return <li onClick={()=>{setSelectCount(index + 1);onModuleSelect(index+1)}} className={`${selectCount == index +1 ? 'active-dot' : ''} ${portalName === 'agency' ? '' : 'client-slide'}`}></li>
                        })}
                    </ol>
                </div>

            </div>
        </>
    )
}

export default React.memo(ProductDetailCard);
