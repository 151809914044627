import React from "react";
import { Footer, HeaderAlternative,Header } from "../../components/common";
// import AboutUs from "../../components/aboutUs";

export class TermsLayout extends React.Component {
  state = {
  }


  render() {
    return (
      <>
        {/* <HeaderAlternative /> */}
        <Header/>
        {/* {this.state.isCompanyAvi ?
          this.props.children : ''
        } */}
        <div className="mt-5 pt-3">
          {this.props.children}
        </div>
        <Footer />
      </>
    )
  }
}

