import React from 'react'
import { useState } from 'react'
import { HashLink } from 'react-router-hash-link';

import serviceProvider from '../../assets/svg/serviceProvider.svg'
import serviceSeeker from '../../assets/svg/serviceSeeker.svg'
import closeButton from '../../assets/svg/mdiclosebox.svg'
import './style.scss'
import { useHistory } from 'react-router-dom';

const JoinAS = ({show, handleDialogBox, email}) => {
   
    const [service, setServie] = useState(0)
    const history = useHistory()
  return (
    
        <div className='global-box-join-as'>
           <div className='main-box-join-as'>
              <div className='join-as-close-button' onClick={handleDialogBox}>
              <img  src={closeButton} style={{width:'24px'}} alt='close button'/>
              </div>
              <div className='main-title text-center pt-2 '>
                  Join AS 
              </div>
              
              <div className='d-flex flex-column flex-md-row justify-content-around align-items-center' >
                    <div id='service-provider' className={'cursor-pointer main-box-staffing-service main-box-provider'+` ${service === 1 ? 'selected-service' : ''}`}
                    onClick={()=>setServie(1)}
                    >
                    <img src={serviceProvider} className='my-2' alt='Staffing Service Provider'/>
                    <div className='text-center'>
                    <p className='title text-center '>Staffing Service Provider</p>
                    <p className='subtitle text-center text-secondary'>Offering staffing solutions to the companies</p>
                    </div>
                    </div>

                    <div id='service-seeker' className={'cursor-pointer main-box-staffing-service main-box-seeker'+` ${service === 2 ? 'selected-service' : ''}`}
                    onClick={()=>setServie(2)}
                    >
                      <img src={serviceSeeker} className='my-2' alt='Staffing Service Seeker'/>
                      <div className='text-center'>
                      <p className='title text-center '>Staffing Service Seeker</p>
                      <p className='subtitle text-center text-secondary'>Seeking for staffing provider agencies</p>
                      </div>
                    </div>
              </div>
              <div className='w-100 mt-1 mt-md-3 d-flex flex-row justify-content-center align-items-center'>
                <button id='create-free-trail-account' className={'px-5 fs-16 btn-create-account'+` ${service  ? 'active-create-account' : ''}` }
                disabled={service===0}
                onClick={()=> history.push(service === 1 ? `/createFreeProviderAccount${email ? '/'+email : ''}` : service === 2 ? `/createFreeSeekerAccount${email ? '/'+email : ''}` : '')}
                >
                       Create Free Account
                </button>
              </div>
              <div className='w-100 my-3 d-flex flex-row justify-content-center align-items-center'>
              Already have an account ?<HashLink target="_blank" smooth to={`/auth/login`}><span id='login' className='ml-1 cursor-pointer font-weight-bold' style={{color:'#008000'}}> Log in</span></HashLink> 
              </div>
           </div>
        </div>

    
  )
}

export default JoinAS