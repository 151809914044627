import React from "react";
import './contactUs.scss';
import { NormalButton } from "../../../common";
import { HashLink } from 'react-router-hash-link';

// import icon1 from '../../../../assets/images/icons/icon1.svg';
export class ContactUs extends React.Component {

    state = {}

    
    render() {
        return (
            <section id="contactUs" className="pt-0">
                <div className="container-fluid bg">
                    <div className="row">
                        <div className="container">
                            <div className="clients">
                                <h1>For Large Enterprise Subscription  </h1>
                                <HashLink smooth to="#contact-form">
                                    <NormalButton 
                                        className="orange-blue"
                                        label="Contact us"
                                        />
                                </HashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
