import React, { useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import winningMedalIcon from "assets/svg/winning-medal-award.svg";
import PlusPlanIcon from "assets/svg/plan-plus-medal.svg";
import Gradient1 from "assets/svg/plan-back-view-gradient-1.svg";
import Gradient2 from "assets/svg/plan-back-view-gradient-2.svg";
import Gradient3 from "assets/svg/plan-back-view-gradient-3.svg";
import Gradient4 from "assets/svg/plan-back-view-gradient-4.svg";
import Gradient5 from "assets/svg/plan-back-view-gradient-5.svg";
import starsIcon from "assets/svg/plan-stars.svg";
import approveIcon from "assets/svg/approve.svg";
import { planFeatureLabels, standardPlanFeatures } from "service/utilities";

const bg = [Gradient1, Gradient2, Gradient3, Gradient4, Gradient5];
const planTypes = {
  0: "Regular",
  1: "Popular",
  2: "Recommended",
};
const AgencyPlanBox = (props) => {
  let { plan, planInterval, view } = props;
  let { baseAmount, currency, symbol, billingType } = plan;

  const [isSelected, setIsSelected] = useState(false)
  const history = useHistory();
  const handleNavigate = () =>{
    setIsSelected(true)
    setTimeout(()=>{
      history.push(`/subscription/${plan?.id}/${planInterval ? "Yearly" : "Monthly"}/${
        view === 0 ? "provider" : "seeker"
      }`)
    },300)
  }

  return (
      <div className="agency-plan-box" onClick={handleNavigate} key={isSelected}>
        <img className="gradient-bg" src={bg[Math.floor(Math.random() * 5)]} alt="subsciption"/>
        <div key={plan.id} className={`plan-price-box`}>
          <div className="front-view-main-labels">
            <div className="plan-category-label">
              <img
                src={plan.planType === 0 ? winningMedalIcon : PlusPlanIcon}
                alt="plan-category"
                style={{ width: "32px" }}
              />{" "}
              {planTypes[plan?.planType]}
            </div>
          </div>
          <div className="ml-4 mt-4">
            <span className="back-view-plan-price-span2"> {symbol} </span>
            <span className="back-view-plan-price-span">{baseAmount}</span>
            <span className="back-view-plan-price-span3">
              /
              {billingType == 1
                ? "Monthly"
                : billingType == 2
                ? "Yearly"
                : null}
            </span>
          </div>
          <div className="grey-label ml-4">
            {`${plan.baseUserLimit} Staff + Unlimited Clients`}
          </div>

          <div className={`subscription-plan-type`} style={{background:isSelected?'#DEC1E2':''}}>
            {plan.planName.length < 45
              ? plan.planName
              : `${plan.planName.slice(0, 44)} . . .`}
          </div>
          {standardPlanFeatures.map((item) => {
            return (
              <div className="d-flex justify-content-start pl-4 mb-1">
                <img
                  src={approveIcon}
                  className="mr-2"
                  alt="approved"
                  style={{ width: "16px" }}
                />
                <span className="feature-name">{item}</span>
              </div>
            );
          })}
          {plan.features &&
            plan.features.map((item) => {
              return (
                item.value !== "Editing profile details" && (
                  <div className="d-flex justify-content-start pl-4 mb-1">
                    <img
                      src={approveIcon}
                      className="mr-2"
                      alt="approved"
                      style={{ width: "16px" }}
                    />
                    <span className="feature-name">
                      {planFeatureLabels[item.value]
                        ? planFeatureLabels[item.value]
                        : item.value}
                    </span>
                  </div>
                )
              );
            })}
          {plan.pricingModel == 2 ? (
            <div className="dynamic-seat">
              {" "}
              <img
                src={starsIcon}
                alt="Dynamic Seat Licensing"
                style={{ width: "16px" }}
              />{" "}
              Dynamic Seat Licensing
            </div>
          ) : null}
        </div>
      </div>
  );
};

export default React.memo(AgencyPlanBox);
