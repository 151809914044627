import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import "./App.css";
import "./assets/scss/index.scss";

class App extends React.Component {

  render() {
    return (
      <div>
        
        <BrowserRouter>
          <Routes />
        </BrowserRouter>

      </div>
    );
  }
}

export default App;