import React from "react";
import Swiper from 'react-id-swiper';
import './howDifferent.scss';
export class HowDifferent extends React.Component {

    state = {
        params : {
            loop: true,
            spaceBetween: 30,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: false,
                type: 'bullets'
            },
            containerClass: 'swiper-container different'
          }
    }

    
    render() {
        let { params,  } = this.state
        let companies = [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            "Lore ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
            "quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
                        ]
        // Load single page for Home
        const loadSingle = (item, index) => {
            
            return (
                <div className="corousel-outline" key={index}>
                    <p>{item}</p>
                </div>
            )
        }

       

        // Load carousel for Additional Home
        const loadCarousel = (data) => {
            
            return (
                data.map((item, index) => loadSingle(item, index))
            )
        }

        return (
            <section id="howDifferent">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clients">
                                <h1>How we are different</h1>
                                <div className="swiper">
                                    {/* <div className="card"> */}
                                        <Swiper {...params}
                                         shouldSwiperUpdate
                                         >

                                            {loadCarousel(companies)}
                                        </Swiper>
                                       

                                    {/* </div> */}
                                <div className="corousel-outline marg" >
                                    <p>60% of Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
